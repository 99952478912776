
import { Button, TextField } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useContext } from "react"
import RootContext from '../../data/RootStore'
import ColorItem from '../parts/ColorItem'
import LinkIcons from '../parts/LinkIcons'

const PageContact= observer(() => {
  const rc = useContext(RootContext)
  const size = 30
  let topMargin = 5
  if (rc.ui.isNarrow()) {
    topMargin = 10
  }

  if (!rc.ui.editMode) {

  return (
    <div >
      <br/><br/><br/>
      <div style={{fontFamily:rc.mf.config.font.super,fontSize:'3em'}}>You Can Contact Us</div>
      <br/>
      Call us any time
      <div style={{fontWeight:'bold',fontSize:'2em'}}>{rc.mf.config.mqPhone}</div>
      <br/>
      Send us an e-mail to
      <div style={{fontWeight:'bold',fontSize:'2em'}}>{rc.mf.config.mqEMail}</div>
      <br/>
      Or on the Wedding Socials:
      <div>
      <LinkIcons />
      </div>

      <br/><br/><br/>
      <div style={{fontFamily:rc.mf.config.font.super,fontSize:'3em'}}>We Can Contact You</div>
            < TextField required  style={{ marginTop: 5 }} fullWidth label={"Names"} 
              value={rc.mf.quote.names} sx={{maxWidth:400}}
              onChange={(e: any) => { rc.mf.quote.names = e.target.value }}            
            /><br/>
            < TextField required  style={{ marginTop: 5 }} fullWidth label={"Phone"} 
              value={rc.mf.quote.phone} sx={{maxWidth:400}}
              onChange={(e: any) => { rc.mf.quote.phone = e.target.value }}            
            /><br/>
            < TextField style={{ marginTop: 5 }} fullWidth label={"E-Mail"} 
              value={rc.mf.quote.email} sx={{maxWidth:400}}
              onChange={(e: any) => { rc.mf.quote.email = e.target.value }}            
            /><br/>
            < TextField style={{ marginTop: 5 }} fullWidth label={"Pin Board"} 
              value={rc.mf.quote.pinBoard} sx={{maxWidth:400}}
              onChange={(e: any) => { rc.mf.quote.pinBoard = e.target.value }}            
            />
            <div>
              Colors<br/>
            <ColorItem title='Primary' onChange={(val:any)=>{rc.mf.quote.color1=val}} color={rc.mf.quote.color1}/>
            <ColorItem title='Accent' onChange={(val:any)=>{rc.mf.quote.color2=val}} color={rc.mf.quote.color2}/>
            <ColorItem title='Highlight' onChange={(val:any)=>{rc.mf.quote.color3=val}} color={rc.mf.quote.color3}/>
            </div><br/>
            < TextField required  style={{ marginTop: 5 }} fullWidth label={"What are you thinking of ordering?"} 
              value={rc.mf.quote.notes} sx={{maxWidth:400}} multiline minRows={4}
              onChange={(e: any) => { rc.mf.quote.notes = e.target.value }}            
            /><br/><br/>
            { !rc.mf.quoteSent &&
              <Button variant="contained" onClick={()=>{rc.mf.sendQuote()}}>Request A Quote</Button>
            }
            { rc.mf.quoteSent &&
              <div>Your request has been sent!  We'll reach out to you soon!</div>
            }
            <br/><br/><br/>


      
    </div>
  )
  }

  return     <div >
  <h1>You Can Contact Us</h1>
  <p>Call us any time at:</p>
  < TextField required  style={{ marginTop: 5 }} fullWidth label={"Moda Quote Phone"} 
              value={rc.mf.config.mqPhone} sx={{maxWidth:400}}
              onChange={(e: any) => { rc.mf.config.mqPhone = ''+e.target.value }}            
            />
  <p>Send us an e-mail at:</p>
  < TextField required  style={{ marginTop: 5 }} fullWidth label={"Moda Quote Phone"} 
              value={rc.mf.config.mqEMail} sx={{maxWidth:400}}
              onChange={(e: any) => { rc.mf.config.mqEMail = ''+e.target.value }}            
            />
  </div>

  
})

export default PageContact