
import React from 'react'

import { observer } from 'mobx-react-lite'
import { useContext } from "react"
import RootContext from '../../data/RootStore'
import TextField from '@mui/material/TextField';
import { Box,  Container, Card, CardMedia, CardContent, Typography } from '@mui/material';

const showGallery= (galIdx:number)=>{


  // ts-ignore:next-line
  const foo = document.querySelector("#nanoGallery1")

  // @ts-expect-error
  window.$("#nanoGallery1").nanoGallery({
    kind: 'flickr',
    photoset: '72157661790745790',
    thumbnailWidth: 250,
    thumbnailHeight: 250,
    thumbnailLabel: {
      displayDescription: true,
      hideIcons: true,
      display: false,
      position: 'overImageOnBottom'

    },
    thumbnailHoverEffect: 'borderLighter',
    viewerToolbar: {
      standard: 'minimizeButton, previousButton, pageCounter, nextButton, playPauseButton, fullscreenButton, closeButton',
      minimized: 'minimizeButton'
    }
  });
  
  
}

const PageGallery = observer(() => {
  const rc = useContext(RootContext)
  const size = 30
  let topMargin = 5
  let cardWide = rc.ui.screenWide/4
  let cardHigh = rc.ui.screenHigh -100
  let flowDir = 'row'

  if (rc.ui.isNarrow()) {
    topMargin = 10
    flowDir = 'column'
    cardWide = rc.ui.screenWide-10
    cardHigh = rc.ui.screenHigh / 4.5
  }

  const objectFlowStyle = { display: 'flex', flexDirection: flowDir, justifyContent: 'space-evenly' } as React.CSSProperties


  const items = rc.mf.config.galleryData.map( (i,idx)=>
    <div 
        onClick={(i)=>{ 
          rc.ui.showGallery = idx; 
          rc.ui.curPage='GalleryView'
          window.history.pushState({urlPath:'/GalleryView'},'','/GalleryView')
         }}
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', maxWidth:cardWide, maxHeight:cardHigh, margin: 15, borderRadius: 4, boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 2px 2px 2px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)' }}>
      <img src={i.url} style={{ maxHeight:cardHigh-50, width: '100%', borderRadius: 4, objectFit:'contain' }} />
      <div style={{ fontFamily: rc.mf.config.font.super, fontSize: '3em' }}>{i.text}</div>
    </div>
  )

  const edits = rc.mf.config.galleryData.map((i, idx) =>
      < TextField required
        id = "outlined-required"
        label = {i.text+" Image URL"}
        value = { rc.mf.config.galleryData[idx].url }
        onChange = {(e) => { rc.mf.config.galleryData[idx].url = e.target.value }}
      />
  )


  return (

<div style={{width:'100%'}}>

        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
          <div style={{ fontFamily: rc.mf.config.font.super, fontSize:'4em',margin:15 }}>
            Gallery
          </div>

          <div style={objectFlowStyle}>
            {items}
          </div>
        </Box>  

        {rc.ui.editMode && !rc.ui.isNarrow() &&
          <div>
              <br /><br />
              Copy these image URL from flicker.<br/>
              For best results use more "Square-ish" images so they look good on both web and phone.<br />
              <br />

              <div style={objectFlowStyle}>
                {edits}
              </div>
          </div>
        }
</div>

  )
})



export default PageGallery