import { rgbToHex, TextField } from "@mui/material"
import { useRef, useState } from "react"
import { SketchPicker, ColorResult } from 'react-color'
import { ReadableByteStreamController } from "stream/web"
import ColorList from "./ColorListAll"

import { observer } from 'mobx-react-lite'

import { useContext } from "react"
import RootContext from '../../data/RootStore'

import styled from 'styled-components';

const ColorBox = styled.div` 
  display:inline-block; 
  font-size: 75%;
  white-space: pre;
  vertical-align: bottom;
  padding:2px;
`;

function getContrastYIQ(hexcolor:string){
  if(!hexcolor){return 'black'}
  hexcolor = hexcolor.replace("#", "");
  var r = parseInt(hexcolor.substr(0,2),16);
  var g = parseInt(hexcolor.substr(2,2),16);
  var b = parseInt(hexcolor.substr(4,2),16);
  var yiq = ((r*299)+(g*587)+(b*114))/1000;
  return (yiq >= 128) ? 'black' : 'white';
}

type ColorItemProps = {
    color: any
    title?: string
    onChange: (val:any)=>void
  }


const ColorItem = observer(
  ({ color , onChange, title }: ColorItemProps) => {

  const rc = useContext(RootContext)

  const popover = useRef<HTMLDivElement>(null)
  const [isOpen, toggle] = useState(false);

  let colorArray1:Array<any> = rc.ui.pantoneListFormatted.map((clrList)=>{
    return(
      <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start'}}>
        {
          clrList.map((clr)=>{
            if(rc.mf.colorFilter==='' || clr.n.indexOf(rc.mf.colorFilter)!==-1){
              const tclr = getContrastYIQ(clr.c)
              return(<ColorBox 
                  onClick={()=>{rc.mf.colorFilter='';toggle(!isOpen);onChange(clr)}} 
                  onMouseOver={()=>{onChange(clr)}} 
                  style={{color:tclr,backgroundColor:clr?.c,
                  minWidth:'55px',minHeight:'55px',maxWidth:'55px',width:'55px'}} >
                {clr?.text}
              </ColorBox>)}
            })
        }
      </div>
    )
  })
  let colorArray2:Array<any> = rc.ui.allColorListFormatted.map((clrList)=>{
    return(
      <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start'}}>
        {
          clrList.map((clr)=>{
            const tclr = getContrastYIQ(clr.c)
            if(rc.mf.colorFilter==='' || clr.n.indexOf(rc.mf.colorFilter)!==-1){
              return(
            <ColorBox 
              onClick={()=>{rc.mf.colorFilter='';toggle(!isOpen);onChange(clr)}} 
              onMouseOver={()=>{onChange(clr)}} 
              style={{color:tclr,backgroundColor:clr.c,
                minWidth:'55px',minHeight:'55px',maxWidth:'55px',width:'55px'}} >
              {clr.text}
            </ColorBox>)}
          })
        }
      </div>
    )
  })

  const tclrM = getContrastYIQ(color?.c)

  return (
    <div style={{display:'inline-block'}}>
      <ColorBox
        style={{ backgroundColor: color?.c,  width:155,height:70, border:'2px solid black', borderRadius:10  }}
        onClick={() => toggle(!isOpen)}
      ><span style={{color:tclrM}}>{title+'\n'+color?.text}</span></ColorBox>


      {isOpen && 
        <div style={{border:'1px solid black', borderRadius:5}}>
        <div style={{maxWidth:'100%',minWidth:450,maxHeight:300,overflow:'auto',alignItems:'left'}}>
          <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start'}} >
            {colorArray1}
            {colorArray2}
          </div>
        </div>
        Color Name Filter: &nbsp;<input type="text" id="fname" name="fname" value={rc.mf.colorFilter} onChange={(e: any) => { rc.mf.colorFilter = e.target.value }}/>

        </div>
      }


    </div>
  )
}
)

export default ColorItem