
import { TextField } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useContext } from "react"
import RootContext from '../../data/RootStore'
import { DataGrid, GridColDef} from '@mui/x-data-grid';

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Item', width: 200 },
  { field: 'price', headerName: 'Starting Price', width: 140, align:'right' },
]

const PagePraise = observer(() => {
  const rc = useContext(RootContext)
  const size = 30
  let topMargin = 5
  if (rc.ui.isNarrow()) {
    topMargin = 10
  }

  let items = []
  let rows:Array<any>=[]
  if(!rc.ui.editMode){
    rows = rc.mf.config.serviceData.serviceList.trim().split('\n').map( (line,idx)=>{ 
      if(line){
        try{
          let parts=line.split('=')
          return{
            id:idx,
            name:parts[0],
            price:'$'+parts[1]+'+'
          }
        }
        catch(err){ console.log(err)}        
      }
    })
  }
  else{
    items.push(
    < TextField required
      label='Intro'
      
      fullWidth
      multiline
      minRows={3}
        style={{ margin: 5, maxWidth: '95%' }}
      value={rc.mf.config.serviceData.intro}
      onChange={(e: any) => { rc.mf.config.serviceData.intro = e.target.value }}
    />
    )
    items.push(
      < TextField required
        label='Items List'
        
        fullWidth
        multiline
        minRows={15}
        style={{ margin: 5, maxWidth: '95%' }}
        value={rc.mf.config.serviceData.serviceList}
        onChange={(e: any) => { rc.mf.config.serviceData.serviceList = e.target.value }}
      />
    )
    items.push(
      < TextField required
        label='Outro'
        
        fullWidth
        multiline
        minRows={3}
        style={{margin:5,maxWidth:'95%'}}
        value={rc.mf.config.serviceData.outro}
        onChange={(e: any) => { rc.mf.config.serviceData.outro = e.target.value }}
      />
    )

  }


  return (
    <div >
      <div style={{ fontFamily: rc.mf.config.font.super, fontSize: '4em', margin: 15 }}>
        Services
      </div>
      <div style={{display:'flex',justifyContent:'space-around'}}>
      <div style={{ maxWidth:500,textAlign:'left',margin:10, marginBottom:20 }}>
        { !rc.ui.editMode &&
        <>
          <div style={{ whiteSpace: 'pre-wrap' }}>{rc.mf.config.serviceData.intro}</div><br />
          <DataGrid
            density='compact'
            hideFooter={true}
            autoHeight={true}
            rows={rows}
            columns={columns}
            style={{maxWidth:350}}
          /><br/>
          <div style={{ whiteSpace: 'pre-wrap' }}>{rc.mf.config.serviceData.outro}</div>
          </>

        }
        {rc.ui.editMode &&
          <>
          {items}
          </>
        }
      </div>
      </div>
    </div>
  )
})


export default PagePraise