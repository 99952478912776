
import React from 'react';


import { observer } from 'mobx-react-lite'
import { useContext } from "react"

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';

import RootContext from '../../data/RootStore'

import { ReactNode } from 'react';

import { mdiHome } from '@mdi/js';
import { mdiHeart } from '@mdi/js';
import { mdiCarKey } from '@mdi/js';
import { mdiCamera } from '@mdi/js';
import { mdiChatProcessing } from '@mdi/js';
import { mdiRoomServiceOutline } from '@mdi/js';
import { mdiPhoneClassic } from '@mdi/js';
import { mdiFlower } from '@mdi/js';
import { mdiPlaylistEdit } from '@mdi/js';

import { mdiLeadPencil } from '@mdi/js';
import { mdiBookOpenVariant } from '@mdi/js';
import { mdiMenu } from '@mdi/js';

import Icon from "@mdi/react";
import Tooltip from '@mui/material/Tooltip'



type NavButtonProps = {
  text: string,
  hideText?: boolean
  action?: ()=>void
}

const mapIcon=(input:string)=>{
  if(input==='Home') return mdiHome
  if (input === 'Gallery') return mdiCamera
  if (input === 'Blog') return mdiPlaylistEdit
  if (input === 'Praise') return mdiHeart
  if (input === 'Services') return mdiFlower
  if (input === 'Rentals') return mdiCarKey
  if (input === 'Contact') return mdiPhoneClassic
  if (input === 'Load') return mdiBookOpenVariant
  if (input === 'Save') return mdiLeadPencil
  if (input === 'BlogList') return mdiPlaylistEdit
  return mdiHome
}

const NavButton = observer((props: NavButtonProps) => {
  const rc = useContext(RootContext)


  return (
    <div style={{
      maxWidth: 75, textAlign: 'center', display: 'flex',
      flexDirection: 'column',alignItems:'center',justifyContent:'center'}}>
    <Tooltip title={props.text}>
      <Button variant="contained" 
          sx={{"&.MuiButton-contained": { color: rc.mf.config.color.icon.c },}}
          style={{ borderRadius: 50, backgroundColor:rc.mf.config.color.face.c }} 
          onClick={() => {
            if(props.action){props.action()}
            else{
              rc.ui.curPage = props.text; 
              window.history.pushState({urlPath:'/'+rc.ui.curPage},'','/'+rc.ui.curPage)
              rc.ui.navDrawerOpen=false
            }
          }}>
        <Icon path={mapIcon(props.text)} size={1.25} />
      </Button>
      </Tooltip>

    </div>
  )
})

/*

        <span style={{fontFamily:rc.mf.config.font.text}}>
      {props.hideText?'':props.text}
      </span>


alt={props.text} color='primary' sx={{ width: 56, height: 56, bgcolor: 'primary.main' }}

      {rc.mf.counter}<br />
      {rc.mf.timesFive}<br />
      <Button variant="contained" onClick={() => { rc.mf.counter++ }}>bump</Button>
      <Button variant="contained" onClick={() => { rc.mf.counter-- }}>dump</Button>
      <Button variant="contained" onClick={() => { rc.mf.reset() }}>reset</Button>

*/
export default NavButton