
import { TextField } from '@mui/material'
import { CSSProperties } from '@mui/styled-engine'
import { observer } from 'mobx-react-lite'
import { useContext } from "react"
import RootContext from '../../data/RootStore'

const leftCell = { border: '1px solid grey', textAlign: 'left', borderCollapse:'collapse'} as const
const rightCell = { border: '1px solid grey', textAlign: 'right', borderCollapse: 'collapse' } as const

const PagePraise = observer(() => {
  const rc = useContext(RootContext)
  const size = 30
  let topMargin = 5
  if (rc.ui.isNarrow()) {
    topMargin = 10
  }

  let items: Array<any> = []
  let items2: Array<any> = []
  let rows:Array<any>=[]
  if(!rc.ui.editMode){

    const normalItemsList = rc.mf.config.rentalData.normalList.split(/\n\s*\n/)
    items = normalItemsList.map((data,idx)=>{
      const lines = data.trim().split('\n')
      const name = lines[0]
      const img = lines[1]
      const rows = []
      for (var idx = 2; idx < lines.length;idx++){
        const parts = lines[idx].split('=')
        rows.push(<tr><td style={{ border: '1px solid grey' }}>{parts[0]}</td><td style={{ textAlign: 'right', border: '1px solid grey'}}>${parts[1]}</td></tr>)
      }
      return(
        <div style={{display:'inline-block'}}>
        <div style={{width:200,maxWidth:200,margin:15, display:'flex', justifyContent:'space-around', flexDirection:'column'}}>
          <img src={img} style={{ borderRadius: 5, maxWidth: '99%', maxHeight: 170, marginLeft: 'auto', marginRight: 'auto'}}/>
          {name}<br/>
          <table width='100%' cellSpacing={0} cellPadding={2}><tbody>{rows}</tbody></table>      
        </div>
        </div>        
        )
    })

    const textItemsList = rc.mf.config.rentalData.textOnlyList.split(/\n\s*\n/)
    items2 = textItemsList.map((data, idx) => {
      const fixData = data.trim()
      const firstLine = fixData.trim().indexOf('\n')
      const name = fixData.substring(0,firstLine).trim()
      const text = fixData.substring(firstLine).trim()
      return( 
      <div style={{marginBottom:20, margin:15}}>
        <div style={{fontWeight:'bold'}}>{name}</div>
        <div style={{}}>{text}</div>
      </div>)
    })


  }
  else{
    items.push(
      < TextField required
        label='Photo Items'
        
        fullWidth
        multiline
        minRows={10}
        maxRows={20}
        style={{ margin: 5, maxWidth: '95%' }}
        value={rc.mf.config.rentalData.normalList}
        onChange={(e: any) => { rc.mf.config.rentalData.normalList = e.target.value }}
      />
    )
    items.push(
      < TextField required
        label='Text Only Items'
        
        fullWidth
        multiline
        minRows={5}
        maxRows={20}
        style={{margin:5,maxWidth:'95%'}}
        value={rc.mf.config.rentalData.textOnlyList}
        onChange={(e: any) => { rc.mf.config.rentalData.textOnlyList = e.target.value }}
      />
    )

  }


  return (
    <div >
      <div style={{ fontFamily:rc.mf.config.font.super, fontSize: '4em', margin: 15 }}>
        Rentals
      </div>
      <div style={{ maxWidth:1500,marginLeft:'auto',marginRight:'auto',textAlign:'left' }}>
        { !rc.ui.editMode &&
        <>
          {items}<br/>
          {items2}
        </>

        }
        {rc.ui.editMode &&
          <>
          {items}
          </>
        }
      </div>
    </div>
  )
})


export default PagePraise