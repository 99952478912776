

import { observer } from 'mobx-react-lite'

import { AppBar, Button, Container, Drawer, Stack } from '@mui/material';

import Icon from "@mdi/react";
import { mdiMicrosoftXboxControllerMenu } from '@mdi/js';

import NavButton from './parts/NavButton'
import LinkIcons from './parts/LinkIcons'

import { useContext } from "react"
import RootContext from '../data/RootStore'


const Footer = observer(() => {

  const rc = useContext(RootContext)

  return (
        <AppBar position="fixed" sx={{ top: 0, bottom:'auto' }} style={{backgroundColor:rc.mf.config.color.frame.c}} >
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

            {!rc.ui.isNarrow() && <>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', margin: 3 }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: 70, fontFamily:rc.mf.config.font.text }}>
                  <div style={{ display: 'flex', marginLeft: 20, fontSize: '1.25em' }}><a href={`tel:${rc.mf.config.mqPhone}`} style={{ color: 'inherit', textDecoration: 'inherit' }}>{rc.mf.config.mqPhone}</a></div>
                  <div style={{ display: 'flex', marginLeft: 20, fontSize: '1.25em' }}>{rc.mf.config.mqEMail}</div>
                </div>
                <div style={{ position: 'absolute', left: '50%', transform: 'translate(-50%,0)' }}>
                  <Stack direction="row" spacing={2} style={{ marginTop: 5 }}>
                    <NavButton text='Gallery' />
                    <NavButton text='Blog' />
                    <NavButton text='Praise' />
                    <div style={{ marginTop: 15,fontSize: '2.2em', marginRight:15, whiteSpace: 'nowrap', fontFamily:rc.mf.config.font.super }}
                              onClick={() => {

                                  rc.ui.curPage = "Home"; 
                                  window.history.pushState({urlPath:'/'+rc.ui.curPage},'','/'+rc.ui.curPage)
                                  rc.ui.navDrawerOpen=false
                              }}>Moda Floral</div>

                    <NavButton text='Services' />
                    <NavButton text='Rentals' />
                    <NavButton text='Contact' />
                    <div onClick={() => { rc.ui.bumpEdit() }} style={{position:'absolute', right:0, top:0, height:25, width:190}}>                              
                    &nbsp;&nbsp;
                    { /*rc.ui.editMode && <div style={{backgroundColor:'red',borderRadius:10,padding:3, margin:5, userSelect:'none'}} >&nbsp;EDIT MODE</div> */}

                    </div>

                    { rc.ui.editMode &&
                      <>
                      Edit Mode:
                      <NavButton text='Load' action={()=>{rc.mf.loadConfig()}}/>
                      <NavButton text='Save' action={()=>{rc.mf.saveConfig()}}/>
                      <input type='text' value={rc.mf.savePassword} onChange={(evt)=>{rc.mf.savePassword=evt.target.value}} style={{maxHeight:25}}/>
                      </>
                    }
                  </Stack>
                </div>
                <div>
                  <LinkIcons />
                </div>
              </div>
            </>}

            {rc.ui.isNarrow() && <>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 3 }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                  <a href={`tel:${rc.mf.config.mqPhone}`} style={{ color: 'inherit', textDecoration: 'inherit', marginLeft: 5, fontFamily:rc.mf.config.font.text }}>{rc.mf.config.mqPhone}</a>
                </div>
                <div style={{ position: 'absolute', left: '50%', transform: 'translate(-50%,0)' }}>
                  <Button variant='contained' onClick={() => { rc.ui.navDrawerOpen = true; rc.mf.curPost=-1 }}><Icon path={mdiMicrosoftXboxControllerMenu} size={1.5} />Menu</Button>
                  <Drawer
                    anchor='left'
                    open={rc.ui.navDrawerOpen}
                    onClose={() => { rc.ui.navDrawerOpen = false }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', margin: 3, height: '100%', backgroundColor:rc.mf.config.color.frame.c }}>
                      <NavButton text='Home' />
                      <NavButton text='Gallery' />
                      <NavButton text='Blog' />
                      <NavButton text='Praise' />
                      <NavButton text='Services' />
                      <NavButton text='Rentals' />
                      <NavButton text='Contact' />
                    </div>
                  </Drawer>
                </div>
                <LinkIcons />
              </div>
            </>}
          </div>
      </AppBar>
  )
})


export default Footer;
