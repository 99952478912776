
import { observer } from 'mobx-react-lite'
import { useContext } from "react"
import RootContext from '../../data/RootStore'
import NavButton from '../parts/NavButton'
import { mdiPlaylistEdit } from '@mdi/js';
import Icon from "@mdi/react";
import { Translate } from '@mui/icons-material';


const PageBlog = observer(() => {
  const rc = useContext(RootContext)
  const size = 30

  let content = ''
   
  if(rc.mf.blogList && rc.mf.blogList.items[rc.mf.curPost] ){
    content = rc.mf.blogList.items[rc.mf.curPost].content
    // @ts-ignore
    //const array = [...content.matchAll(/src="\K[^"]+/g)];
    //console.log(array)
    content = content.replace(/s320/g, 's800');
    content = content.replace(/s1600/g, 's800');
  }

  const menu =         
  <div style={{textAlign:'left', padding:5}}>
    <div style={{ marginTop: 5,fontSize: '2em', fontWeight:'bold', fontFamily:rc.mf.config.font.super }}>
      <Icon path={mdiPlaylistEdit} size={2} style={{marginTop:10,transform:'translate(0,25%)'}}/> Blog Posts
    </div>
    {
      rc.mf.blogList.items.map((item:any, idx:number)=>{
        return <div style={{padding:5}} 
                  onClick={()=>{rc.mf.curPost=idx;
                    const divVal = document.getElementById('containerDiv')
                    if(divVal){ divVal.scrollTop=0 }
                  }}
               >{ idx===rc.mf.curPost?'▶':''} {item.title||"Untitled Mystery Post"}</div>
      })
    }
  </div>


  let topMargin = 5
  if (!rc.ui.isNarrow()) {
    return (
      <div>
        <div style={{position:'absolute',top:70,left:0,bottom:70,width:rc.ui.screenWide/4, borderRight:'1px solid grey', textAlign:'left', padding:5, }}>
          {menu}
        </div>
        <div id="containerDiv" className="blog" style={{position:'absolute',top:70,right:0,bottom:70,overflow:'auto',padding:10,width:((rc.ui.screenWide/4)*3)-25}} >
          <div>
          {rc.mf.blogList && rc.mf.blogList.items[rc.mf.curPost] &&
          <div dangerouslySetInnerHTML={{ __html: content }}>
            
          </div>
          } 
          </div>
        </div>
      </div>
    )
  }
  else{
    if(rc.mf.blogList && rc.mf.blogList.items[rc.mf.curPost]){
      return( 
        <div style={{width:'100%',height:'100%', overflow:'auto'}} key={'k'+rc.mf.curPost}>
          <div className="blog" style={{padding:10,width:(rc.ui.screenWide/4)*3}} >
            <div dangerouslySetInnerHTML={{ __html: content }}/>          
          </div>

          <div style={{position:'absolute',top:-5,right:-10}}>
            <NavButton text='Blog' hideText={true} action={()=>{rc.mf.curPost=-1}} />
          </div>
        </div>
      )  
    }
    return (
      <div style={{display:'flex',flexDirection:'row'}} >
        {menu}
      </div>
    )

  }
  
})

export default PageBlog