
import { TextField } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useContext } from "react"
import RootContext from '../../data/RootStore'
import { mdiTrashCan } from '@mdi/js';
import { mdiPlus } from '@mdi/js';
import { mdiHeart } from '@mdi/js';
import Icon from "@mdi/react";


const PagePraise = observer(() => {
  const rc = useContext(RootContext)
  const size = 30
  let topMargin = 5
  if (rc.ui.isNarrow()) {
    topMargin = 10
  }

  let items = []
  if(!rc.ui.editMode){
    items = rc.mf.config.praiseData.map( (i,idx)=>{ return(
      <div style={{textAlign:'left', margin:5, marginBottom:30, marginLeft:30, marginRight:30, minHeight:190, verticalAlign:'top'}}>
        <div style={{ fontFamily: rc.mf.config.font.super, fontSize: '2em', marginBottom:-20 }}>{i.name}</div><br/>
        <div style={{ float: 'left', border: '2px grey solid', borderRadius: 5, backgroundSize: 'cover', backgroundImage: 'url('+i.url+')', height:120, width:120,marginRight:5}}>&nbsp;</div>
        <span style={{ whiteSpace: 'pre-wrap'}}>{i.text}</span>
      </div>
    )})
  }
  else{
    items = rc.mf.config.praiseData.map((i, idx) => {
      return (
      <div style={{ textAlign: 'left', margin: 5, marginBottom: 30, marginLeft: 30, marginRight: 30, minHeight: 190, verticalAlign: 'top' }} key={'praiseItem'+idx}>
          <div style={{ display:'inline-block', border: '2px grey solid', borderRadius: 5, backgroundSize:'cover', backgroundImage: 'url('+rc.mf.config.praiseData[idx].url+')', height: 120, width: 120 }} />
          <div style={{ float: 'right', marginTop: 90 }} onClick={() => { rc.mf.deletePraiseItem(idx) }}>
            <span style={{ verticalAlign: 'middle' }}>To remove an item, type 'delete' as the name.  The icon will turn red.  Then click the icon to delete.  </span>
            <Icon style={{ verticalAlign: 'middle' }} path={mdiTrashCan} size={1} color={rc.mf.config.praiseData[idx].name==='delete'?'red':'grey'} />
          </div>

          <br/>
      < TextField required
        
            style={{ marginTop: 10 }}
        fullWidth 
        label={"Image URL"}
        value={rc.mf.config.praiseData[idx].url}
        onChange={(e: any) => { rc.mf.config.praiseData[idx].url = e.target.value }}
      /><br/>
      < TextField required
        
        fullWidth 
        value={rc.mf.config.praiseData[idx].name}
        onChange={(e: any) => { rc.mf.config.praiseData[idx].name = e.target.value }}
      /><br />
      < TextField required
        
        fullWidth 
        multiline
        minRows={3}
        value={rc.mf.config.praiseData[idx].text}
        onChange={(e: any) => { rc.mf.config.praiseData[idx].text = e.target.value }}
      />
    </div>
    )})

    items.unshift(<div style={{ border:'1px solid grey',borderRadius:5,verticalAlign: 'middle', maxWidth:250,margin:20}} onClick={()=>{rc.mf.addPraiseItem()}}>
      <Icon path={mdiPlus} size={1} color='green' style={{ verticalAlign: 'middle' }}/>
      <Icon path={mdiHeart} size={1} color='red' style={{ verticalAlign: 'middle' }} />&nbsp;&nbsp;&nbsp;
      <span style={{ verticalAlign: 'middle' }}>Click here to add Praise</span>
    </div>)
  }

  return (
    <div >
      <div style={{ fontFamily: rc.mf.config.font.super, fontSize: '4em', margin: 15 }}>
        Praise
      </div>
      <div style={{ position: 'absolute', top: 100, bottom: 0, left: 0, right: 0, overflow: 'auto' }}>
        {items}
      </div>
    </div>
  )
})


export default PagePraise