
const ColorList = [
    {
      "n": "white",
      "c": "#FFFFFF"
    },
    {
      "n": "snow",
      "c": "#FFFAFA"
    },
    {
      "n": "mint cream",
      "c": "#F5FFFA"
    },
    {
      "n": "ghost white",
      "c": "#F8F8FF"
    },
    {
      "n": "pale grey",
      "c": "#FDFDFE"
    },
    {
      "n": "white smoke",
      "c": "#F5F5F5"
    },
    {
      "n": "very light pink",
      "c": "#FFF4F2"
    },
    {
      "n": "seashell",
      "c": "#FFF5EE"
    },
    {
      "n": "floral white",
      "c": "#FFFAF0"
    },
    {
      "n": "ivory",
      "c": "#FFFFF0"
    },
    {
      "n": "honeydew",
      "c": "#F0FFF0"
    },
    {
      "n": "azure",
      "c": "#F0FFFF"
    },
    {
      "n": "alice blue",
      "c": "#F0F8FF"
    },
    {
      "n": "lavender blush",
      "c": "#FFF0F5"
    },
    {
      "n": "misty rose",
      "c": "#FFE4E1"
    },
    {
      "n": "linen",
      "c": "#FAF0E6"
    },
    {
      "n": "old lace",
      "c": "#FDF5E6"
    },
    {
      "n": "off white",
      "c": "#FFFFE4"
    },
    {
      "n": "lavender",
      "c": "#E6E6FA"
    },
    {
      "n": "cornsilk",
      "c": "#FFF8DC"
    },
    {
      "n": "light yellow",
      "c": "#FFFFE0"
    },
    {
      "n": "ice blue",
      "c": "#D7FFFE"
    },
    {
      "n": "light cyan",
      "c": "#E0FFFF"
    },
    {
      "n": "antique white",
      "c": "#FAEBD7"
    },
    {
      "n": "blanched almond",
      "c": "#FFEBCD"
    },
    {
      "n": "papaya whip",
      "c": "#FFEFD5"
    },
    {
      "n": "pale",
      "c": "#FFF9D0"
    },
    {
      "n": "lemon chiffon",
      "c": "#FFFACD"
    },
    {
      "n": "beige",
      "c": "#F5F5DC"
    },
    {
      "n": "light goldenrod yellow",
      "c": "#FAFAD2"
    },
    {
      "n": "eggshell",
      "c": "#FFFFD4"
    },
    {
      "n": "ice",
      "c": "#D6FFFA"
    },
    {
      "n": "very pale blue",
      "c": "#D6FFFE"
    },
    {
      "n": "really light blue",
      "c": "#D4FFFF"
    },
    {
      "n": "very light blue",
      "c": "#D5FFFF"
    },
    {
      "n": "pale blue",
      "c": "#D0FEFE"
    },
    {
      "n": "pale lavender",
      "c": "#EECFFE"
    },
    {
      "n": "pale mauve",
      "c": "#FED0FC"
    },
    {
      "n": "pale pink",
      "c": "#FFCFDC"
    },
    {
      "n": "bisque",
      "c": "#FFE4C4"
    },
    {
      "n": "egg shell",
      "c": "#FFFCC4"
    },
    {
      "n": "cream",
      "c": "#FFFFC2"
    },
    {
      "n": "eggshell blue",
      "c": "#C4FFF7"
    },
    {
      "n": "light light blue",
      "c": "#CAFFFB"
    },
    {
      "n": "light lavender",
      "c": "#DFC5FE"
    },
    {
      "n": "pale lilac",
      "c": "#E4CBFF"
    },
    {
      "n": "light lilac",
      "c": "#EDC8FF"
    },
    {
      "n": "very light purple",
      "c": "#F6CEFC"
    },
    {
      "n": "light rose",
      "c": "#FFC5CB"
    },
    {
      "n": "gainsboro",
      "c": "#DCDCDC"
    },
    {
      "n": "peach puff",
      "c": "#FFDAB9"
    },
    {
      "n": "very light green",
      "c": "#D1FFBD"
    },
    {
      "n": "very pale green",
      "c": "#CFFDBC"
    },
    {
      "n": "pale aqua",
      "c": "#B8FFEB"
    },
    {
      "n": "duck egg blue",
      "c": "#C3FBF4"
    },
    {
      "n": "pale sky blue",
      "c": "#BDF6FE"
    },
    {
      "n": "light periwinkle",
      "c": "#C1C6FC"
    },
    {
      "n": "light lavendar",
      "c": "#EFC0FE"
    },
    {
      "n": "pastel pink",
      "c": "#FFBACD"
    },
    {
      "n": "pink",
      "c": "#FFC0CB"
    },
    {
      "n": "pale rose",
      "c": "#FDC1C5"
    },
    {
      "n": "light peach",
      "c": "#FFD8B1"
    },
    {
      "n": "moccasin",
      "c": "#FFE4B5"
    },
    {
      "n": "parchment",
      "c": "#FEFCAF"
    },
    {
      "n": "light beige",
      "c": "#FFFEB6"
    },
    {
      "n": "creme",
      "c": "#FFFFB6"
    },
    {
      "n": "light light green",
      "c": "#C8FFB0"
    },
    {
      "n": "celadon",
      "c": "#BEFDB7"
    },
    {
      "n": "light mint",
      "c": "#B6FFBB"
    },
    {
      "n": "pale cyan",
      "c": "#B7FFFA"
    },
    {
      "n": "light violet",
      "c": "#D6B4FC"
    },
    {
      "n": "powder pink",
      "c": "#FFB2D0"
    },
    {
      "n": "baby pink",
      "c": "#FFB7CE"
    },
    {
      "n": "soft pink",
      "c": "#FDB0C0"
    },
    {
      "n": "light pink",
      "c": "#FFB6C1"
    },
    {
      "n": "light grey",
      "c": "#D3D3D3"
    },
    {
      "n": "navajo white",
      "c": "#FFDEAD"
    },
    {
      "n": "wheat",
      "c": "#F5DEB3"
    },
    {
      "n": "pale peach",
      "c": "#FFE5AD"
    },
    {
      "n": "light tan",
      "c": "#FBEEAC"
    },
    {
      "n": "light seafoam green",
      "c": "#A7FFB5"
    },
    {
      "n": "pale violet",
      "c": "#CEAEFA"
    },
    {
      "n": "pale salmon",
      "c": "#FFB19A"
    },
    {
      "n": "dark cream",
      "c": "#FFF39A"
    },
    {
      "n": "pale goldenrod",
      "c": "#EEE8AA"
    },
    {
      "n": "buff",
      "c": "#FEF69E"
    },
    {
      "n": "tea green",
      "c": "#BDF8A3"
    },
    {
      "n": "washed out green",
      "c": "#BCF5A6"
    },
    {
      "n": "light sage",
      "c": "#BCECAC"
    },
    {
      "n": "pastel green",
      "c": "#B0FF9D"
    },
    {
      "n": "light pastel green",
      "c": "#B2FBA5"
    },
    {
      "n": "light mint green",
      "c": "#A6FBB2"
    },
    {
      "n": "light seafoam",
      "c": "#A0FEBF"
    },
    {
      "n": "pale turquoise",
      "c": "#AFEEEE"
    },
    {
      "n": "baby blue",
      "c": "#A2CFFE"
    },
    {
      "n": "light blue grey",
      "c": "#B7C9E2"
    },
    {
      "n": "pastel blue",
      "c": "#A2BFFE"
    },
    {
      "n": "pastel purple",
      "c": "#CAA0FF"
    },
    {
      "n": "light khaki",
      "c": "#E6F2A2"
    },
    {
      "n": "celery",
      "c": "#C1FD95"
    },
    {
      "n": "pale light green",
      "c": "#B1FC99"
    },
    {
      "n": "pale green",
      "c": "#98FB98"
    },
    {
      "n": "mint green",
      "c": "#8FFF9F"
    },
    {
      "n": "robin's egg blue",
      "c": "#98EFF9"
    },
    {
      "n": "powder blue",
      "c": "#B0E0E6"
    },
    {
      "n": "light blue",
      "c": "#ADD8E6"
    },
    {
      "n": "light steel blue",
      "c": "#B0C4DE"
    },
    {
      "n": "baby purple",
      "c": "#CA9BF7"
    },
    {
      "n": "peachy pink",
      "c": "#FF9A8A"
    },
    {
      "n": "manilla",
      "c": "#FFFA86"
    },
    {
      "n": "light yellowish green",
      "c": "#C2FF89"
    },
    {
      "n": "pistachio",
      "c": "#C0FA8B"
    },
    {
      "n": "foam green",
      "c": "#90FDA9"
    },
    {
      "n": "light aqua",
      "c": "#8CFFDB"
    },
    {
      "n": "robin egg blue",
      "c": "#8AF1FE"
    },
    {
      "n": "cloudy blue",
      "c": "#ACC2D9"
    },
    {
      "n": "carolina blue",
      "c": "#8AB8FE"
    },
    {
      "n": "periwinkle blue",
      "c": "#8F99FB"
    },
    {
      "n": "liliac",
      "c": "#C48EFD"
    },
    {
      "n": "rosa",
      "c": "#FE86A4"
    },
    {
      "n": "silver",
      "c": "#C0C0C0"
    },
    {
      "n": "blush",
      "c": "#F29E8E"
    },
    {
      "n": "peach",
      "c": "#FFB07C"
    },
    {
      "n": "khaki",
      "c": "#F0E68C"
    },
    {
      "n": "banana",
      "c": "#FFFF7E"
    },
    {
      "n": "yellowish tan",
      "c": "#FCFC81"
    },
    {
      "n": "butter",
      "c": "#FFFF81"
    },
    {
      "n": "pale yellow",
      "c": "#FFFF84"
    },
    {
      "n": "faded yellow",
      "c": "#FEFF7F"
    },
    {
      "n": "light yellow green",
      "c": "#CCFD7F"
    },
    {
      "n": "light pea green",
      "c": "#C4FE82"
    },
    {
      "n": "light grey green",
      "c": "#B7E1A1"
    },
    {
      "n": "easter green",
      "c": "#8CFD7E"
    },
    {
      "n": "light green",
      "c": "#90EE90"
    },
    {
      "n": "aquamarine",
      "c": "#7FFFD4"
    },
    {
      "n": "light sky blue",
      "c": "#87CEFA"
    },
    {
      "n": "sky",
      "c": "#82CAFC"
    },
    {
      "n": "lavender blue",
      "c": "#8B88F8"
    },
    {
      "n": "plum",
      "c": "#DDA0DD"
    },
    {
      "n": "bubblegum pink",
      "c": "#FE83CC"
    },
    {
      "n": "pinky",
      "c": "#FC86AA"
    },
    {
      "n": "faded pink",
      "c": "#DE9DAC"
    },
    {
      "n": "blush pink",
      "c": "#FE828C"
    },
    {
      "n": "light coral",
      "c": "#F08080"
    },
    {
      "n": "pinkish grey",
      "c": "#C8ACA9"
    },
    {
      "n": "light salmon",
      "c": "#FFA07A"
    },
    {
      "n": "sandy yellow",
      "c": "#FDEE73"
    },
    {
      "n": "straw",
      "c": "#FCF679"
    },
    {
      "n": "custard",
      "c": "#FFFD78"
    },
    {
      "n": "butter yellow",
      "c": "#FFFD74"
    },
    {
      "n": "pastel yellow",
      "c": "#FFFE71"
    },
    {
      "n": "pale lime",
      "c": "#BEFD73"
    },
    {
      "n": "lightgreen",
      "c": "#76FF7B"
    },
    {
      "n": "light bluish green",
      "c": "#76FDA8"
    },
    {
      "n": "seafoam",
      "c": "#80F9AD"
    },
    {
      "n": "seafoam green",
      "c": "#7AF9AB"
    },
    {
      "n": "light blue green",
      "c": "#7EFBB3"
    },
    {
      "n": "light teal",
      "c": "#90E4C1"
    },
    {
      "n": "light aquamarine",
      "c": "#7BFDC7"
    },
    {
      "n": "light turquoise",
      "c": "#7EF4CC"
    },
    {
      "n": "sky blue",
      "c": "#87CEEB"
    },
    {
      "n": "lightblue",
      "c": "#7BC8F6"
    },
    {
      "n": "light grey blue",
      "c": "#9DBCD4"
    },
    {
      "n": "perrywinkle",
      "c": "#8F8CE7"
    },
    {
      "n": "pig pink",
      "c": "#E78EA5"
    },
    {
      "n": "salmon pink",
      "c": "#FE7B7C"
    },
    {
      "n": "salmon",
      "c": "#FA8072"
    },
    {
      "n": "apricot",
      "c": "#FFB16D"
    },
    {
      "n": "pale gold",
      "c": "#FDDE6C"
    },
    {
      "n": "sandy",
      "c": "#F1DA7A"
    },
    {
      "n": "yellow tan",
      "c": "#FFE36E"
    },
    {
      "n": "light lime",
      "c": "#AEFD6C"
    },
    {
      "n": "key lime",
      "c": "#AEFF6E"
    },
    {
      "n": "tiffany blue",
      "c": "#7BF2DA"
    },
    {
      "n": "easter purple",
      "c": "#C071FE"
    },
    {
      "n": "light purple",
      "c": "#BF77F6"
    },
    {
      "n": "hot pink",
      "c": "#FF69B4"
    },
    {
      "n": "bubblegum",
      "c": "#FF6CB5"
    },
    {
      "n": "bubble gum pink",
      "c": "#FF69AF"
    },
    {
      "n": "dark salmon",
      "c": "#E9967A"
    },
    {
      "n": "pinkish tan",
      "c": "#D99B82"
    },
    {
      "n": "burly wood",
      "c": "#DEB887"
    },
    {
      "n": "tan",
      "c": "#D2B48C"
    },
    {
      "n": "sand yellow",
      "c": "#FCE166"
    },
    {
      "n": "yellowish",
      "c": "#FAEE66"
    },
    {
      "n": "canary",
      "c": "#FDFF63"
    },
    {
      "n": "light lime green",
      "c": "#B9FF66"
    },
    {
      "n": "pale lime green",
      "c": "#B1FF65"
    },
    {
      "n": "light grass green",
      "c": "#9AF764"
    },
    {
      "n": "lighter green",
      "c": "#75FD63"
    },
    {
      "n": "pale purple",
      "c": "#B790D4"
    },
    {
      "n": "bright lavender",
      "c": "#C760FF"
    },
    {
      "n": "violet pink",
      "c": "#FB5FFC"
    },
    {
      "n": "lavender pink",
      "c": "#DD85D7"
    },
    {
      "n": "purply pink",
      "c": "#F075E6"
    },
    {
      "n": "candy pink",
      "c": "#FF63E9"
    },
    {
      "n": "dull pink",
      "c": "#D5869D"
    },
    {
      "n": "rosy pink",
      "c": "#F6688E"
    },
    {
      "n": "dusty pink",
      "c": "#D58A94"
    },
    {
      "n": "coral pink",
      "c": "#FF6163"
    },
    {
      "n": "dark grey",
      "c": "#A9A9A9"
    },
    {
      "n": "grapefruit",
      "c": "#FD5956"
    },
    {
      "n": "orange pink",
      "c": "#FF6F52"
    },
    {
      "n": "melon",
      "c": "#FF7855"
    },
    {
      "n": "sandy brown",
      "c": "#F4A460"
    },
    {
      "n": "pale orange",
      "c": "#FFA756"
    },
    {
      "n": "very light brown",
      "c": "#D3B683"
    },
    {
      "n": "light mustard",
      "c": "#F7D560"
    },
    {
      "n": "sand",
      "c": "#E2CA76"
    },
    {
      "n": "light gold",
      "c": "#FDDC5C"
    },
    {
      "n": "lemon",
      "c": "#FDFF52"
    },
    {
      "n": "pear",
      "c": "#CBF85F"
    },
    {
      "n": "light bright green",
      "c": "#53FE5C"
    },
    {
      "n": "light green blue",
      "c": "#56FCA2"
    },
    {
      "n": "light greenish blue",
      "c": "#63F7B4"
    },
    {
      "n": "cornflower blue",
      "c": "#6495ED"
    },
    {
      "n": "medium slate blue",
      "c": "#7B68EE"
    },
    {
      "n": "bright lilac",
      "c": "#C95EFB"
    },
    {
      "n": "light magenta",
      "c": "#FA5FF7"
    },
    {
      "n": "medium pink",
      "c": "#F36196"
    },
    {
      "n": "light mauve",
      "c": "#C292A1"
    },
    {
      "n": "grey pink",
      "c": "#C3909B"
    },
    {
      "n": "greyish pink",
      "c": "#C88D94"
    },
    {
      "n": "rosy brown",
      "c": "#BC8F8F"
    },
    {
      "n": "pinkish orange",
      "c": "#FF724C"
    },
    {
      "n": "coral",
      "c": "#FF7F50"
    },
    {
      "n": "pastel orange",
      "c": "#FF964F"
    },
    {
      "n": "fawn",
      "c": "#CFAF7B"
    },
    {
      "n": "putty",
      "c": "#BEAE8A"
    },
    {
      "n": "maize",
      "c": "#F4D054"
    },
    {
      "n": "dull yellow",
      "c": "#EEDC5B"
    },
    {
      "n": "banana yellow",
      "c": "#FAFE4B"
    },
    {
      "n": "greenish beige",
      "c": "#C9D179"
    },
    {
      "n": "pale olive",
      "c": "#B9CC81"
    },
    {
      "n": "pale olive green",
      "c": "#B1D27B"
    },
    {
      "n": "dark sea green",
      "c": "#8FBC8F"
    },
    {
      "n": "light neon green",
      "c": "#4EFD54"
    },
    {
      "n": "pale teal",
      "c": "#82CBB2"
    },
    {
      "n": "seafoam blue",
      "c": "#78D1B6"
    },
    {
      "n": "soft blue",
      "c": "#6488EA"
    },
    {
      "n": "medium purple",
      "c": "#9370DB"
    },
    {
      "n": "lighter purple",
      "c": "#A55AF4"
    },
    {
      "n": "orchid",
      "c": "#DA70D6"
    },
    {
      "n": "pale violet red",
      "c": "#DB7093"
    },
    {
      "n": "muted pink",
      "c": "#D1768F"
    },
    {
      "n": "warm pink",
      "c": "#FB5581"
    },
    {
      "n": "old rose",
      "c": "#C87F89"
    },
    {
      "n": "tomato",
      "c": "#FF6347"
    },
    {
      "n": "orangish",
      "c": "#FC824A"
    },
    {
      "n": "orangeish",
      "c": "#FD8D49"
    },
    {
      "n": "faded orange",
      "c": "#F0944D"
    },
    {
      "n": "mushroom",
      "c": "#BA9E88"
    },
    {
      "n": "light orange",
      "c": "#FDAA48"
    },
    {
      "n": "butterscotch",
      "c": "#FDB147"
    },
    {
      "n": "toupe",
      "c": "#C7AC7D"
    },
    {
      "n": "sandstone",
      "c": "#C9AE74"
    },
    {
      "n": "greyish",
      "c": "#A8A495"
    },
    {
      "n": "canary yellow",
      "c": "#FFFE40"
    },
    {
      "n": "greenish tan",
      "c": "#BCCB7A"
    },
    {
      "n": "light moss green",
      "c": "#A6C875"
    },
    {
      "n": "lightish green",
      "c": "#61E160"
    },
    {
      "n": "bright cyan",
      "c": "#41FDFE"
    },
    {
      "n": "wisteria",
      "c": "#A87DC2"
    },
    {
      "n": "heliotrope",
      "c": "#D94FF5"
    },
    {
      "n": "pale magenta",
      "c": "#D767AD"
    },
    {
      "n": "dusky pink",
      "c": "#CC7A8B"
    },
    {
      "n": "pinkish",
      "c": "#D46A7E"
    },
    {
      "n": "old pink",
      "c": "#C77986"
    },
    {
      "n": "watermelon",
      "c": "#FD4659"
    },
    {
      "n": "dirty pink",
      "c": "#CA7B80"
    },
    {
      "n": "light red",
      "c": "#FF474C"
    },
    {
      "n": "brownish pink",
      "c": "#C27E79"
    },
    {
      "n": "dark peach",
      "c": "#DE7E5D"
    },
    {
      "n": "taupe",
      "c": "#B9A281"
    },
    {
      "n": "stone",
      "c": "#ADA587"
    },
    {
      "n": "cement",
      "c": "#A5A391"
    },
    {
      "n": "sunshine yellow",
      "c": "#FFFD37"
    },
    {
      "n": "lemon yellow",
      "c": "#FDFF38"
    },
    {
      "n": "off yellow",
      "c": "#F1F33F"
    },
    {
      "n": "kiwi",
      "c": "#9CEF43"
    },
    {
      "n": "greenish grey",
      "c": "#96AE8D"
    },
    {
      "n": "medium aquamarine",
      "c": "#66CDAA"
    },
    {
      "n": "cool grey",
      "c": "#95A3A6"
    },
    {
      "n": "bluegrey",
      "c": "#85A3B2"
    },
    {
      "n": "lightish blue",
      "c": "#3D7AFD"
    },
    {
      "n": "purpley",
      "c": "#8756E4"
    },
    {
      "n": "lightish purple",
      "c": "#A552E6"
    },
    {
      "n": "dusty lavender",
      "c": "#AC86A8"
    },
    {
      "n": "dusty rose",
      "c": "#C0737A"
    },
    {
      "n": "indian red",
      "c": "#CD5C5C"
    },
    {
      "n": "pastel red",
      "c": "#DB5856"
    },
    {
      "n": "dusty orange",
      "c": "#F0833A"
    },
    {
      "n": "mango",
      "c": "#FFA62B"
    },
    {
      "n": "sand brown",
      "c": "#CBA560"
    },
    {
      "n": "desert",
      "c": "#CCAD60"
    },
    {
      "n": "dark khaki",
      "c": "#BDB76B"
    },
    {
      "n": "light olive",
      "c": "#ACBF69"
    },
    {
      "n": "tan green",
      "c": "#A9BE70"
    },
    {
      "n": "green yellow",
      "c": "#ADFF2F"
    },
    {
      "n": "sage green",
      "c": "#88B378"
    },
    {
      "n": "soft green",
      "c": "#6FC276"
    },
    {
      "n": "bright light green",
      "c": "#2DFE54"
    },
    {
      "n": "greenish cyan",
      "c": "#2AFEB7"
    },
    {
      "n": "greyblue",
      "c": "#77A1B5"
    },
    {
      "n": "dark sky blue",
      "c": "#448EE4"
    },
    {
      "n": "light royal blue",
      "c": "#3A2EFE"
    },
    {
      "n": "dark periwinkle",
      "c": "#665FD1"
    },
    {
      "n": "light indigo",
      "c": "#6D5ACF"
    },
    {
      "n": "purpleish blue",
      "c": "#6140EF"
    },
    {
      "n": "deep lilac",
      "c": "#966EBD"
    },
    {
      "n": "heather",
      "c": "#A484AC"
    },
    {
      "n": "medium orchid",
      "c": "#BA55D3"
    },
    {
      "n": "purpleish pink",
      "c": "#DF4EC8"
    },
    {
      "n": "purplish pink",
      "c": "#CE5DAE"
    },
    {
      "n": "reddish pink",
      "c": "#FE2C54"
    },
    {
      "n": "lightish red",
      "c": "#FE2F4A"
    },
    {
      "n": "pale red",
      "c": "#D9544D"
    },
    {
      "n": "orangey red",
      "c": "#FA4224"
    },
    {
      "n": "pale brown",
      "c": "#B1916E"
    },
    {
      "n": "dust",
      "c": "#B2996E"
    },
    {
      "n": "camel",
      "c": "#C69F59"
    },
    {
      "n": "macaroni and cheese",
      "c": "#EFB435"
    },
    {
      "n": "sun yellow",
      "c": "#FFDF22"
    },
    {
      "n": "lemon lime",
      "c": "#BFFE28"
    },
    {
      "n": "kiwi green",
      "c": "#8EE53F"
    },
    {
      "n": "grey",
      "c": "#929591"
    },
    {
      "n": "grey/green",
      "c": "#86A17D"
    },
    {
      "n": "fresh green",
      "c": "#69D84F"
    },
    {
      "n": "greyish green",
      "c": "#82A67D"
    },
    {
      "n": "faded green",
      "c": "#7BB274"
    },
    {
      "n": "hot green",
      "c": "#25FF29"
    },
    {
      "n": "weird green",
      "c": "#3AE57F"
    },
    {
      "n": "turquoise",
      "c": "#40E0D0"
    },
    {
      "n": "bright light blue",
      "c": "#26F7FD"
    },
    {
      "n": "faded blue",
      "c": "#658CBB"
    },
    {
      "n": "clear blue",
      "c": "#247AFD"
    },
    {
      "n": "royal blue",
      "c": "#4169E1"
    },
    {
      "n": "warm blue",
      "c": "#4B57DB"
    },
    {
      "n": "slate blue",
      "c": "#6A5ACD"
    },
    {
      "n": "bluish purple",
      "c": "#703BE7"
    },
    {
      "n": "electric purple",
      "c": "#AA23FF"
    },
    {
      "n": "amethyst",
      "c": "#9B5FC0"
    },
    {
      "n": "soft purple",
      "c": "#A66FB5"
    },
    {
      "n": "pinkish purple",
      "c": "#D648D7"
    },
    {
      "n": "pink purple",
      "c": "#DB4BDA"
    },
    {
      "n": "purple pink",
      "c": "#E03FD8"
    },
    {
      "n": "darkish pink",
      "c": "#DA467D"
    },
    {
      "n": "mauve",
      "c": "#AE7181"
    },
    {
      "n": "red pink",
      "c": "#FA2A55"
    },
    {
      "n": "pinky red",
      "c": "#FC2647"
    },
    {
      "n": "dusky rose",
      "c": "#BA6873"
    },
    {
      "n": "strawberry",
      "c": "#FB2943"
    },
    {
      "n": "dark coral",
      "c": "#CF524E"
    },
    {
      "n": "reddish orange",
      "c": "#F8481C"
    },
    {
      "n": "warm grey",
      "c": "#978A84"
    },
    {
      "n": "sunny yellow",
      "c": "#FFF917"
    },
    {
      "n": "lime yellow",
      "c": "#D0FE1D"
    },
    {
      "n": "yellowy green",
      "c": "#BFF128"
    },
    {
      "n": "light olive green",
      "c": "#A4BE5C"
    },
    {
      "n": "radioactive green",
      "c": "#2CFA1F"
    },
    {
      "n": "dusty green",
      "c": "#76A973"
    },
    {
      "n": "spearmint",
      "c": "#1EF876"
    },
    {
      "n": "wintergreen",
      "c": "#20F986"
    },
    {
      "n": "aqua marine",
      "c": "#2EE8BB"
    },
    {
      "n": "medium turquoise",
      "c": "#48D1CC"
    },
    {
      "n": "dodger blue",
      "c": "#1E90FF"
    },
    {
      "n": "vivid blue",
      "c": "#152EFF"
    },
    {
      "n": "purpley blue",
      "c": "#5F34E7"
    },
    {
      "n": "purple blue",
      "c": "#632DE9"
    },
    {
      "n": "purplish blue",
      "c": "#601EF9"
    },
    {
      "n": "burple",
      "c": "#6832E3"
    },
    {
      "n": "deep lavender",
      "c": "#8D5EB7"
    },
    {
      "n": "pinky purple",
      "c": "#C94CBE"
    },
    {
      "n": "faded red",
      "c": "#D3494E"
    },
    {
      "n": "pinkish brown",
      "c": "#B17261"
    },
    {
      "n": "orangered",
      "c": "#FE420F"
    },
    {
      "n": "terracotta",
      "c": "#CA6641"
    },
    {
      "n": "terracota",
      "c": "#CB6843"
    },
    {
      "n": "dull orange",
      "c": "#D8863B"
    },
    {
      "n": "peru",
      "c": "#CD853F"
    },
    {
      "n": "yellowish orange",
      "c": "#FFAB0F"
    },
    {
      "n": "dark beige",
      "c": "#AC9362"
    },
    {
      "n": "orangey yellow",
      "c": "#FDB915"
    },
    {
      "n": "golden yellow",
      "c": "#FEC615"
    },
    {
      "n": "poison green",
      "c": "#40FD14"
    },
    {
      "n": "grey green",
      "c": "#789B73"
    },
    {
      "n": "neon green",
      "c": "#0CFF0C"
    },
    {
      "n": "tea",
      "c": "#65AB7C"
    },
    {
      "n": "greyish teal",
      "c": "#719F91"
    },
    {
      "n": "bright turquoise",
      "c": "#0FFEF9"
    },
    {
      "n": "bluish grey",
      "c": "#748B97"
    },
    {
      "n": "grey blue",
      "c": "#6B8BA4"
    },
    {
      "n": "light slate grey",
      "c": "#778899"
    },
    {
      "n": "blue violet",
      "c": "#8A2BE2"
    },
    {
      "n": "neon purple",
      "c": "#BC13FE"
    },
    {
      "n": "dark lilac",
      "c": "#9C6DA5"
    },
    {
      "n": "purpley grey",
      "c": "#947E94"
    },
    {
      "n": "pink/purple",
      "c": "#EF1DE7"
    },
    {
      "n": "deep pink",
      "c": "#FF1493"
    },
    {
      "n": "dark pink",
      "c": "#CB416B"
    },
    {
      "n": "deep rose",
      "c": "#C74767"
    },
    {
      "n": "reddish",
      "c": "#C44240"
    },
    {
      "n": "reddish grey",
      "c": "#997570"
    },
    {
      "n": "vermillion",
      "c": "#F4320C"
    },
    {
      "n": "red orange",
      "c": "#FD3C06"
    },
    {
      "n": "clay",
      "c": "#B66A50"
    },
    {
      "n": "blood orange",
      "c": "#FE4B03"
    },
    {
      "n": "terra cotta",
      "c": "#C9643B"
    },
    {
      "n": "adobe",
      "c": "#BD6C48"
    },
    {
      "n": "pumpkin orange",
      "c": "#FB7D07"
    },
    {
      "n": "tangerine",
      "c": "#FF9408"
    },
    {
      "n": "orange yellow",
      "c": "#FFAD01"
    },
    {
      "n": "squash",
      "c": "#F2AB15"
    },
    {
      "n": "dark sand",
      "c": "#A88F59"
    },
    {
      "n": "amber",
      "c": "#FEB308"
    },
    {
      "n": "golden rod",
      "c": "#F9BC08"
    },
    {
      "n": "marigold",
      "c": "#FCC006"
    },
    {
      "n": "sunflower yellow",
      "c": "#FFDA03"
    },
    {
      "n": "dandelion",
      "c": "#FEDF08"
    },
    {
      "n": "bright yellow",
      "c": "#FFFD01"
    },
    {
      "n": "neon yellow",
      "c": "#CFFF04"
    },
    {
      "n": "greeny yellow",
      "c": "#C6F808"
    },
    {
      "n": "yellowgreen",
      "c": "#BBF90F"
    },
    {
      "n": "electric lime",
      "c": "#A8FF04"
    },
    {
      "n": "acid green",
      "c": "#8FFE09"
    },
    {
      "n": "bright lime",
      "c": "#87FD05"
    },
    {
      "n": "bright lime green",
      "c": "#65FE08"
    },
    {
      "n": "turtle green",
      "c": "#75B84F"
    },
    {
      "n": "dull green",
      "c": "#74A662"
    },
    {
      "n": "green grey",
      "c": "#77926F"
    },
    {
      "n": "highlighter green",
      "c": "#1BFC06"
    },
    {
      "n": "electric green",
      "c": "#21FC0D"
    },
    {
      "n": "fluro green",
      "c": "#0AFF02"
    },
    {
      "n": "fluorescent green",
      "c": "#08FF08"
    },
    {
      "n": "dark pastel green",
      "c": "#56AE57"
    },
    {
      "n": "bright green",
      "c": "#01FF07"
    },
    {
      "n": "algae",
      "c": "#54AC68"
    },
    {
      "n": "dark mint",
      "c": "#48C072"
    },
    {
      "n": "minty green",
      "c": "#0BF77D"
    },
    {
      "n": "bright sea green",
      "c": "#05FFA6"
    },
    {
      "n": "bright aqua",
      "c": "#0BF9EA"
    },
    {
      "n": "neon blue",
      "c": "#04D9FF"
    },
    {
      "n": "bright sky blue",
      "c": "#02CCFE"
    },
    {
      "n": "cool blue",
      "c": "#4984B8"
    },
    {
      "n": "dusty blue",
      "c": "#5A86AD"
    },
    {
      "n": "steel",
      "c": "#738595"
    },
    {
      "n": "off blue",
      "c": "#5684AE"
    },
    {
      "n": "slate grey",
      "c": "#708090"
    },
    {
      "n": "azul",
      "c": "#1D5DEC"
    },
    {
      "n": "electric blue",
      "c": "#0652FF"
    },
    {
      "n": "blurple",
      "c": "#5539CC"
    },
    {
      "n": "bluey purple",
      "c": "#6241C7"
    },
    {
      "n": "purply blue",
      "c": "#661AEE"
    },
    {
      "n": "bright violet",
      "c": "#AD0AFD"
    },
    {
      "n": "greyish purple",
      "c": "#887191"
    },
    {
      "n": "bright purple",
      "c": "#BE03FD"
    },
    {
      "n": "faded purple",
      "c": "#916E99"
    },
    {
      "n": "purple/pink",
      "c": "#D725DE"
    },
    {
      "n": "bright magenta",
      "c": "#FF08E8"
    },
    {
      "n": "purpley pink",
      "c": "#C83CB9"
    },
    {
      "n": "shocking pink",
      "c": "#FE02A2"
    },
    {
      "n": "electric pink",
      "c": "#FF0490"
    },
    {
      "n": "strong pink",
      "c": "#FF0789"
    },
    {
      "n": "neon red",
      "c": "#FF073A"
    },
    {
      "n": "dusty red",
      "c": "#B9484E"
    },
    {
      "n": "red",
      "c": "#FF0000"
    },
    {
      "n": "dull red",
      "c": "#BB3F3F"
    },
    {
      "n": "orangish red",
      "c": "#F43605"
    },
    {
      "n": "orange red",
      "c": "#FF4500"
    },
    {
      "n": "bright orange",
      "c": "#FF5B00"
    },
    {
      "n": "tan brown",
      "c": "#AB7E4C"
    },
    {
      "n": "light brown",
      "c": "#AD8150"
    },
    {
      "n": "puce",
      "c": "#A57E52"
    },
    {
      "n": "dark orange",
      "c": "#FF8C00"
    },
    {
      "n": "dark tan",
      "c": "#AF884A"
    },
    {
      "n": "orange",
      "c": "#FFA500"
    },
    {
      "n": "yellow orange",
      "c": "#FCB001"
    },
    {
      "n": "goldenrod",
      "c": "#DAA520"
    },
    {
      "n": "brown grey",
      "c": "#8D8468"
    },
    {
      "n": "gold",
      "c": "#F5BF03"
    },
    {
      "n": "yellow",
      "c": "#FFFF00"
    },
    {
      "n": "greenish yellow",
      "c": "#CDFD02"
    },
    {
      "n": "lemon green",
      "c": "#ADF802"
    },
    {
      "n": "yellow green",
      "c": "#9ACD32"
    },
    {
      "n": "bright yellow green",
      "c": "#9DFF00"
    },
    {
      "n": "chartreuse",
      "c": "#7FFF00"
    },
    {
      "n": "lawn green",
      "c": "#7CFC00"
    },
    {
      "n": "green apple",
      "c": "#5EDC1F"
    },
    {
      "n": "medium grey",
      "c": "#7D7F7C"
    },
    {
      "n": "off green",
      "c": "#6BA353"
    },
    {
      "n": "vivid green",
      "c": "#2FEF10"
    },
    {
      "n": "lime",
      "c": "#00FF00"
    },
    {
      "n": "lime green",
      "c": "#32CD32"
    },
    {
      "n": "spring green",
      "c": "#00FF7F"
    },
    {
      "n": "turquoise green",
      "c": "#04F489"
    },
    {
      "n": "medium spring green",
      "c": "#00FA9A"
    },
    {
      "n": "greenish turquoise",
      "c": "#00FBB0"
    },
    {
      "n": "grey teal",
      "c": "#5E9B8A"
    },
    {
      "n": "greeny blue",
      "c": "#42B395"
    },
    {
      "n": "dull teal",
      "c": "#5F9E8F"
    },
    {
      "n": "bright teal",
      "c": "#01F9C6"
    },
    {
      "n": "cyan",
      "c": "#00FFFF"
    },
    {
      "n": "cadet blue",
      "c": "#5F9EA0"
    },
    {
      "n": "deep sky blue",
      "c": "#00BFFF"
    },
    {
      "n": "steel grey",
      "c": "#6F828A"
    },
    {
      "n": "greyish blue",
      "c": "#5E819D"
    },
    {
      "n": "steel blue",
      "c": "#4682B4"
    },
    {
      "n": "windows blue",
      "c": "#3778BF"
    },
    {
      "n": "bright blue",
      "c": "#0165FC"
    },
    {
      "n": "vibrant blue",
      "c": "#0339F8"
    },
    {
      "n": "rich blue",
      "c": "#021BF9"
    },
    {
      "n": "blue",
      "c": "#0000FF"
    },
    {
      "n": "primary blue",
      "c": "#0804F9"
    },
    {
      "n": "strong blue",
      "c": "#0C06F7"
    },
    {
      "n": "blue purple",
      "c": "#5729CE"
    },
    {
      "n": "vivid purple",
      "c": "#9900FA"
    },
    {
      "n": "dark lavender",
      "c": "#856798"
    },
    {
      "n": "dark orchid",
      "c": "#9932CC"
    },
    {
      "n": "grey purple",
      "c": "#826D8C"
    },
    {
      "n": "hot purple",
      "c": "#CB00F5"
    },
    {
      "n": "magenta",
      "c": "#FF00FF"
    },
    {
      "n": "purple grey",
      "c": "#866F85"
    },
    {
      "n": "hot magenta",
      "c": "#F504C9"
    },
    {
      "n": "bright pink",
      "c": "#FE01B1"
    },
    {
      "n": "neon pink",
      "c": "#FE019A"
    },
    {
      "n": "pink red",
      "c": "#F5054F"
    },
    {
      "n": "pinkish red",
      "c": "#F10C45"
    },
    {
      "n": "dark rose",
      "c": "#B5485D"
    },
    {
      "n": "cherry red",
      "c": "#F7022A"
    },
    {
      "n": "bright red",
      "c": "#FF000D"
    },
    {
      "n": "fire engine red",
      "c": "#FE0002"
    },
    {
      "n": "tomato red",
      "c": "#EC2D01"
    },
    {
      "n": "brownish",
      "c": "#9C6D57"
    },
    {
      "n": "chocolate",
      "c": "#D2691E"
    },
    {
      "n": "clay brown",
      "c": "#B2713D"
    },
    {
      "n": "mocha",
      "c": "#9D7651"
    },
    {
      "n": "brownish orange",
      "c": "#CB7723"
    },
    {
      "n": "coffee",
      "c": "#A6814C"
    },
    {
      "n": "yellowish green",
      "c": "#B0DD16"
    },
    {
      "n": "apple green",
      "c": "#76CD26"
    },
    {
      "n": "moss",
      "c": "#769958"
    },
    {
      "n": "leafy green",
      "c": "#51B73B"
    },
    {
      "n": "muted green",
      "c": "#5FA052"
    },
    {
      "n": "mid green",
      "c": "#50A747"
    },
    {
      "n": "slate green",
      "c": "#658D6D"
    },
    {
      "n": "cool green",
      "c": "#33B864"
    },
    {
      "n": "medium sea green",
      "c": "#3CB371"
    },
    {
      "n": "dark seafoam green",
      "c": "#3EAF76"
    },
    {
      "n": "greenish teal",
      "c": "#32BF84"
    },
    {
      "n": "aqua green",
      "c": "#12E193"
    },
    {
      "n": "aqua blue",
      "c": "#02D8E9"
    },
    {
      "n": "battleship grey",
      "c": "#6B7C85"
    },
    {
      "n": "blue grey",
      "c": "#607C8E"
    },
    {
      "n": "grey/blue",
      "c": "#647D8E"
    },
    {
      "n": "stormy blue",
      "c": "#507B9C"
    },
    {
      "n": "cerulean blue",
      "c": "#056EEE"
    },
    {
      "n": "french blue",
      "c": "#436BAD"
    },
    {
      "n": "purple/blue",
      "c": "#5D21D0"
    },
    {
      "n": "purply",
      "c": "#983FB2"
    },
    {
      "n": "purpleish",
      "c": "#98568D"
    },
    {
      "n": "light plum",
      "c": "#9D5783"
    },
    {
      "n": "crimson",
      "c": "#DC143C"
    },
    {
      "n": "pumpkin",
      "c": "#E17701"
    },
    {
      "n": "brownish grey",
      "c": "#86775F"
    },
    {
      "n": "avocado",
      "c": "#90B134"
    },
    {
      "n": "flat green",
      "c": "#699D4C"
    },
    {
      "n": "vibrant green",
      "c": "#0ADD08"
    },
    {
      "n": "medium green",
      "c": "#39AD48"
    },
    {
      "n": "greenish",
      "c": "#40A368"
    },
    {
      "n": "seaweed green",
      "c": "#35AD6B"
    },
    {
      "n": "algae green",
      "c": "#21C36F"
    },
    {
      "n": "tealish green",
      "c": "#0CDC73"
    },
    {
      "n": "seaweed",
      "c": "#18D17B"
    },
    {
      "n": "greenblue",
      "c": "#23C48B"
    },
    {
      "n": "dull blue",
      "c": "#49759C"
    },
    {
      "n": "bluish",
      "c": "#2976BB"
    },
    {
      "n": "flat blue",
      "c": "#3C73A8"
    },
    {
      "n": "blue blue",
      "c": "#2242C7"
    },
    {
      "n": "pure blue",
      "c": "#0203E2"
    },
    {
      "n": "vibrant purple",
      "c": "#AD03DE"
    },
    {
      "n": "purplish grey",
      "c": "#7A687F"
    },
    {
      "n": "muted purple",
      "c": "#805B87"
    },
    {
      "n": "dusty purple",
      "c": "#825F87"
    },
    {
      "n": "purplish",
      "c": "#94568C"
    },
    {
      "n": "dusky purple",
      "c": "#895B7B"
    },
    {
      "n": "cerise",
      "c": "#DE0C62"
    },
    {
      "n": "light burgundy",
      "c": "#A8415B"
    },
    {
      "n": "light maroon",
      "c": "#A24857"
    },
    {
      "n": "deep orange",
      "c": "#DC4D01"
    },
    {
      "n": "earth",
      "c": "#A2653E"
    },
    {
      "n": "copper",
      "c": "#B66325"
    },
    {
      "n": "leather",
      "c": "#AC7434"
    },
    {
      "n": "burnt yellow",
      "c": "#D5AB09"
    },
    {
      "n": "dark yellow",
      "c": "#D5B60A"
    },
    {
      "n": "mustard yellow",
      "c": "#D2BD0A"
    },
    {
      "n": "dirty yellow",
      "c": "#CDC50A"
    },
    {
      "n": "pea",
      "c": "#A4BF20"
    },
    {
      "n": "camo",
      "c": "#7F8F4E"
    },
    {
      "n": "leaf",
      "c": "#71AA34"
    },
    {
      "n": "grass",
      "c": "#5CAC2D"
    },
    {
      "n": "dark sage",
      "c": "#598556"
    },
    {
      "n": "light forest green",
      "c": "#4F9153"
    },
    {
      "n": "jade green",
      "c": "#2BAF6A"
    },
    {
      "n": "dark mint green",
      "c": "#20C073"
    },
    {
      "n": "bluey green",
      "c": "#2BB179"
    },
    {
      "n": "dusty teal",
      "c": "#4C9085"
    },
    {
      "n": "tealish",
      "c": "#24BCA8"
    },
    {
      "n": "dirty blue",
      "c": "#3F829D"
    },
    {
      "n": "water blue",
      "c": "#0E87CC"
    },
    {
      "n": "metallic blue",
      "c": "#4F738E"
    },
    {
      "n": "muted blue",
      "c": "#3B719F"
    },
    {
      "n": "mid blue",
      "c": "#276AB3"
    },
    {
      "n": "dusky blue",
      "c": "#475F94"
    },
    {
      "n": "twilight",
      "c": "#4E518B"
    },
    {
      "n": "blueberry",
      "c": "#464196"
    },
    {
      "n": "ultramarine blue",
      "c": "#1805DB"
    },
    {
      "n": "dull purple",
      "c": "#84597E"
    },
    {
      "n": "medium violet red",
      "c": "#C71585"
    },
    {
      "n": "dark hot pink",
      "c": "#D90166"
    },
    {
      "n": "dim grey",
      "c": "#696969"
    },
    {
      "n": "brown",
      "c": "#A52A2A"
    },
    {
      "n": "fire brick",
      "c": "#B22222"
    },
    {
      "n": "sienna",
      "c": "#A0522D"
    },
    {
      "n": "rusty orange",
      "c": "#CD5909"
    },
    {
      "n": "rust orange",
      "c": "#C45508"
    },
    {
      "n": "browny orange",
      "c": "#CA6B02"
    },
    {
      "n": "dark taupe",
      "c": "#7F684E"
    },
    {
      "n": "dirty orange",
      "c": "#C87606"
    },
    {
      "n": "dull brown",
      "c": "#876E4B"
    },
    {
      "n": "grey brown",
      "c": "#7F7053"
    },
    {
      "n": "yellow ochre",
      "c": "#CB9D06"
    },
    {
      "n": "mustard",
      "c": "#CEB301"
    },
    {
      "n": "brownish yellow",
      "c": "#C9B003"
    },
    {
      "n": "gross green",
      "c": "#A0BF16"
    },
    {
      "n": "fern green",
      "c": "#548D44"
    },
    {
      "n": "ocean green",
      "c": "#3D9973"
    },
    {
      "n": "dark seafoam",
      "c": "#1FB57A"
    },
    {
      "n": "sea",
      "c": "#3C9992"
    },
    {
      "n": "topaz",
      "c": "#13BBAF"
    },
    {
      "n": "light sea green",
      "c": "#20B2AA"
    },
    {
      "n": "dark turquoise",
      "c": "#00CED1"
    },
    {
      "n": "cerulean",
      "c": "#0485D1"
    },
    {
      "n": "denim blue",
      "c": "#3B5B92"
    },
    {
      "n": "sapphire",
      "c": "#2138AB"
    },
    {
      "n": "dusk",
      "c": "#4E5481"
    },
    {
      "n": "true blue",
      "c": "#010FCC"
    },
    {
      "n": "medium blue",
      "c": "#0000CD"
    },
    {
      "n": "violet blue",
      "c": "#510AC9"
    },
    {
      "n": "dark violet",
      "c": "#9400D3"
    },
    {
      "n": "barney",
      "c": "#AC1DB8"
    },
    {
      "n": "light eggplant",
      "c": "#894585"
    },
    {
      "n": "dark mauve",
      "c": "#874C62"
    },
    {
      "n": "cherry",
      "c": "#CF0234"
    },
    {
      "n": "brick",
      "c": "#A03623"
    },
    {
      "n": "brick orange",
      "c": "#C14A09"
    },
    {
      "n": "cocoa",
      "c": "#875F42"
    },
    {
      "n": "sepia",
      "c": "#985E2B"
    },
    {
      "n": "greyish brown",
      "c": "#7A6A4F"
    },
    {
      "n": "dark goldenrod",
      "c": "#B8860B"
    },
    {
      "n": "ochre",
      "c": "#BF9005"
    },
    {
      "n": "ocre",
      "c": "#C69C04"
    },
    {
      "n": "ocher",
      "c": "#BF9B0C"
    },
    {
      "n": "dark gold",
      "c": "#B59410"
    },
    {
      "n": "muddy yellow",
      "c": "#BFAC05"
    },
    {
      "n": "olive yellow",
      "c": "#C2B709"
    },
    {
      "n": "drab",
      "c": "#828344"
    },
    {
      "n": "avocado green",
      "c": "#87A922"
    },
    {
      "n": "moss green",
      "c": "#658B38"
    },
    {
      "n": "frog green",
      "c": "#58BC08"
    },
    {
      "n": "shamrock green",
      "c": "#02C14D"
    },
    {
      "n": "teal green",
      "c": "#25A36F"
    },
    {
      "n": "jade",
      "c": "#1FA774"
    },
    {
      "n": "turquoise blue",
      "c": "#06B1C4"
    },
    {
      "n": "slate",
      "c": "#516572"
    },
    {
      "n": "denim",
      "c": "#3B638C"
    },
    {
      "n": "dark slate blue",
      "c": "#483D8B"
    },
    {
      "n": "indigo blue",
      "c": "#3A18B1"
    },
    {
      "n": "warm purple",
      "c": "#952E8F"
    },
    {
      "n": "ruby",
      "c": "#CA0147"
    },
    {
      "n": "lipstick red",
      "c": "#C0022F"
    },
    {
      "n": "brownish red",
      "c": "#9E3623"
    },
    {
      "n": "rusty red",
      "c": "#AF2F0D"
    },
    {
      "n": "burnt sienna",
      "c": "#B04E0F"
    },
    {
      "n": "burnt orange",
      "c": "#C04E01"
    },
    {
      "n": "burnt siena",
      "c": "#B75203"
    },
    {
      "n": "orange brown",
      "c": "#BE6400"
    },
    {
      "n": "brown orange",
      "c": "#B96902"
    },
    {
      "n": "caramel",
      "c": "#AF6F09"
    },
    {
      "n": "yellowy brown",
      "c": "#AE8B0C"
    },
    {
      "n": "mustard green",
      "c": "#A8B504"
    },
    {
      "n": "bright olive",
      "c": "#9CBB04"
    },
    {
      "n": "pea green",
      "c": "#8EAB12"
    },
    {
      "n": "khaki green",
      "c": "#728639"
    },
    {
      "n": "dark lime",
      "c": "#84B701"
    },
    {
      "n": "dark lime green",
      "c": "#7EBD01"
    },
    {
      "n": "swamp",
      "c": "#698339"
    },
    {
      "n": "military green",
      "c": "#667C3E"
    },
    {
      "n": "sea green",
      "c": "#2E8B57"
    },
    {
      "n": "green teal",
      "c": "#0CB577"
    },
    {
      "n": "green/blue",
      "c": "#01C08D"
    },
    {
      "n": "green blue",
      "c": "#06B48B"
    },
    {
      "n": "gunmetal",
      "c": "#536267"
    },
    {
      "n": "rouge",
      "c": "#AB1239"
    },
    {
      "n": "brownish purple",
      "c": "#76424E"
    },
    {
      "n": "scarlet",
      "c": "#BE0119"
    },
    {
      "n": "rust red",
      "c": "#AA2704"
    },
    {
      "n": "rust",
      "c": "#A83C09"
    },
    {
      "n": "burnt umber",
      "c": "#A0450E"
    },
    {
      "n": "cinnamon",
      "c": "#AC4F06"
    },
    {
      "n": "orangish brown",
      "c": "#B25F03"
    },
    {
      "n": "orangey brown",
      "c": "#B16002"
    },
    {
      "n": "raw umber",
      "c": "#A75E09"
    },
    {
      "n": "umber",
      "c": "#B26400"
    },
    {
      "n": "golden brown",
      "c": "#B27A01"
    },
    {
      "n": "mustard brown",
      "c": "#AC7E04"
    },
    {
      "n": "yellow brown",
      "c": "#B79400"
    },
    {
      "n": "brown yellow",
      "c": "#B29705"
    },
    {
      "n": "olive drab",
      "c": "#6B8E23"
    },
    {
      "n": "mossy green",
      "c": "#638B27"
    },
    {
      "n": "kermit green",
      "c": "#5CB200"
    },
    {
      "n": "shamrock",
      "c": "#01B44C"
    },
    {
      "n": "viridian",
      "c": "#1E9167"
    },
    {
      "n": "bluish green",
      "c": "#10A674"
    },
    {
      "n": "light navy blue",
      "c": "#2E5A88"
    },
    {
      "n": "dusk blue",
      "c": "#26538D"
    },
    {
      "n": "ultramarine",
      "c": "#2000B1"
    },
    {
      "n": "purplish red",
      "c": "#B0054B"
    },
    {
      "n": "burnt red",
      "c": "#9F2305"
    },
    {
      "n": "russet",
      "c": "#A13905"
    },
    {
      "n": "bronze",
      "c": "#A87900"
    },
    {
      "n": "hazel",
      "c": "#8E7618"
    },
    {
      "n": "dark mustard",
      "c": "#A88905"
    },
    {
      "n": "muddy green",
      "c": "#657432"
    },
    {
      "n": "dirty green",
      "c": "#667E2C"
    },
    {
      "n": "leaf green",
      "c": "#5CA904"
    },
    {
      "n": "grass green",
      "c": "#3F9B0B"
    },
    {
      "n": "forest green",
      "c": "#228B22"
    },
    {
      "n": "darkish green",
      "c": "#287C37"
    },
    {
      "n": "kelly green",
      "c": "#02AB2E"
    },
    {
      "n": "cobalt",
      "c": "#1E488F"
    },
    {
      "n": "cobalt blue",
      "c": "#030AA7"
    },
    {
      "n": "barney purple",
      "c": "#A00498"
    },
    {
      "n": "dark fuchsia",
      "c": "#9D0759"
    },
    {
      "n": "violet red",
      "c": "#A50055"
    },
    {
      "n": "berry",
      "c": "#990F4B"
    },
    {
      "n": "purplish brown",
      "c": "#6B4247"
    },
    {
      "n": "darkish red",
      "c": "#A90308"
    },
    {
      "n": "deep red",
      "c": "#9A0200"
    },
    {
      "n": "red brown",
      "c": "#8B2E16"
    },
    {
      "n": "auburn",
      "c": "#9A3001"
    },
    {
      "n": "saddle brown",
      "c": "#8B4513"
    },
    {
      "n": "milk chocolate",
      "c": "#7F4E1E"
    },
    {
      "n": "raw sienna",
      "c": "#9A6200"
    },
    {
      "n": "yellowish brown",
      "c": "#9B7A01"
    },
    {
      "n": "pea soup",
      "c": "#929901"
    },
    {
      "n": "dark olive green",
      "c": "#556B2F"
    },
    {
      "n": "sap green",
      "c": "#5C8B15"
    },
    {
      "n": "grassy green",
      "c": "#419C03"
    },
    {
      "n": "emerald",
      "c": "#01A049"
    },
    {
      "n": "teal blue",
      "c": "#01889F"
    },
    {
      "n": "sea blue",
      "c": "#047495"
    },
    {
      "n": "ocean blue",
      "c": "#03719C"
    },
    {
      "n": "light navy",
      "c": "#155084"
    },
    {
      "n": "royal",
      "c": "#0C1793"
    },
    {
      "n": "grape",
      "c": "#6C3461"
    },
    {
      "n": "red violet",
      "c": "#9E0168"
    },
    {
      "n": "deep magenta",
      "c": "#A0025C"
    },
    {
      "n": "reddish purple",
      "c": "#910951"
    },
    {
      "n": "purple red",
      "c": "#990147"
    },
    {
      "n": "carmine",
      "c": "#9D0216"
    },
    {
      "n": "purple brown",
      "c": "#673A3F"
    },
    {
      "n": "brick red",
      "c": "#8F1402"
    },
    {
      "n": "brown red",
      "c": "#922B05"
    },
    {
      "n": "warm brown",
      "c": "#964E02"
    },
    {
      "n": "medium brown",
      "c": "#7F5112"
    },
    {
      "n": "dark yellow green",
      "c": "#728F02"
    },
    {
      "n": "tree green",
      "c": "#2A7E19"
    },
    {
      "n": "emerald green",
      "c": "#028F1E"
    },
    {
      "n": "irish green",
      "c": "#019529"
    },
    {
      "n": "blue green",
      "c": "#137E6D"
    },
    {
      "n": "greenish blue",
      "c": "#0B8B87"
    },
    {
      "n": "ocean",
      "c": "#017B92"
    },
    {
      "n": "peacock blue",
      "c": "#016795"
    },
    {
      "n": "blood red",
      "c": "#980002"
    },
    {
      "n": "dark red",
      "c": "#8B0000"
    },
    {
      "n": "reddish brown",
      "c": "#7F2B0A"
    },
    {
      "n": "rust brown",
      "c": "#8B3103"
    },
    {
      "n": "muddy brown",
      "c": "#886806"
    },
    {
      "n": "mud",
      "c": "#735C12"
    },
    {
      "n": "camo green",
      "c": "#526525"
    },
    {
      "n": "pine",
      "c": "#2B5D34"
    },
    {
      "n": "jungle green",
      "c": "#048243"
    },
    {
      "n": "dark cyan",
      "c": "#008B8B"
    },
    {
      "n": "deep aqua",
      "c": "#08787F"
    },
    {
      "n": "dark blue",
      "c": "#00008B"
    },
    {
      "n": "midnight blue",
      "c": "#191970"
    },
    {
      "n": "dark magenta",
      "c": "#8B008B"
    },
    {
      "n": "darkish purple",
      "c": "#751973"
    },
    {
      "n": "red purple",
      "c": "#820747"
    },
    {
      "n": "red wine",
      "c": "#8C0034"
    },
    {
      "n": "maroon",
      "c": "#800000"
    },
    {
      "n": "greenish brown",
      "c": "#696112"
    },
    {
      "n": "brown green",
      "c": "#706C11"
    },
    {
      "n": "olive",
      "c": "#808000"
    },
    {
      "n": "olive green",
      "c": "#677A04"
    },
    {
      "n": "dark grass green",
      "c": "#388004"
    },
    {
      "n": "green",
      "c": "#008000"
    },
    {
      "n": "dark green blue",
      "c": "#1F6357"
    },
    {
      "n": "bluegreen",
      "c": "#017A79"
    },
    {
      "n": "teal",
      "c": "#008080"
    },
    {
      "n": "dark slate grey",
      "c": "#2F4F4F"
    },
    {
      "n": "charcoal grey",
      "c": "#3C4142"
    },
    {
      "n": "deep sea blue",
      "c": "#015482"
    },
    {
      "n": "dark grey blue",
      "c": "#29465B"
    },
    {
      "n": "twilight blue",
      "c": "#0A437A"
    },
    {
      "n": "darkish blue",
      "c": "#014182"
    },
    {
      "n": "navy",
      "c": "#000080"
    },
    {
      "n": "indigo",
      "c": "#4B0082"
    },
    {
      "n": "purple",
      "c": "#800080"
    },
    {
      "n": "wine",
      "c": "#80013F"
    },
    {
      "n": "bordeaux",
      "c": "#7B002C"
    },
    {
      "n": "wine red",
      "c": "#7B0323"
    },
    {
      "n": "reddy brown",
      "c": "#6E1005"
    },
    {
      "n": "chestnut",
      "c": "#742802"
    },
    {
      "n": "browny green",
      "c": "#6F6C0A"
    },
    {
      "n": "brownish green",
      "c": "#6A6E09"
    },
    {
      "n": "army green",
      "c": "#4B5D16"
    },
    {
      "n": "camouflage green",
      "c": "#4B6113"
    },
    {
      "n": "dark aquamarine",
      "c": "#017371"
    },
    {
      "n": "deep turquoise",
      "c": "#017374"
    },
    {
      "n": "prussian blue",
      "c": "#004577"
    },
    {
      "n": "dark royal blue",
      "c": "#02066F"
    },
    {
      "n": "deep blue",
      "c": "#040273"
    },
    {
      "n": "grape purple",
      "c": "#5D1451"
    },
    {
      "n": "rich purple",
      "c": "#720058"
    },
    {
      "n": "merlot",
      "c": "#730039"
    },
    {
      "n": "blood",
      "c": "#770001"
    },
    {
      "n": "mud brown",
      "c": "#60460F"
    },
    {
      "n": "olive brown",
      "c": "#645403"
    },
    {
      "n": "greeny brown",
      "c": "#696006"
    },
    {
      "n": "mud green",
      "c": "#606602"
    },
    {
      "n": "spruce",
      "c": "#0A5F38"
    },
    {
      "n": "charcoal",
      "c": "#343837"
    },
    {
      "n": "dark aqua",
      "c": "#05696B"
    },
    {
      "n": "dark blue grey",
      "c": "#1F3B4D"
    },
    {
      "n": "marine blue",
      "c": "#01386A"
    },
    {
      "n": "darkblue",
      "c": "#030764"
    },
    {
      "n": "royal purple",
      "c": "#4B006E"
    },
    {
      "n": "claret",
      "c": "#680018"
    },
    {
      "n": "navy green",
      "c": "#35530A"
    },
    {
      "n": "forest",
      "c": "#0B5509"
    },
    {
      "n": "dark green",
      "c": "#006400"
    },
    {
      "n": "marine",
      "c": "#042E60"
    },
    {
      "n": "dark indigo",
      "c": "#1F0954"
    },
    {
      "n": "burgundy",
      "c": "#610023"
    },
    {
      "n": "green brown",
      "c": "#544E03"
    },
    {
      "n": "deep green",
      "c": "#02590F"
    },
    {
      "n": "pine green",
      "c": "#0A481E"
    },
    {
      "n": "dark blue green",
      "c": "#005249"
    },
    {
      "n": "deep teal",
      "c": "#00555A"
    },
    {
      "n": "plum purple",
      "c": "#4E0550"
    },
    {
      "n": "dried blood",
      "c": "#4B0101"
    },
    {
      "n": "mahogany",
      "c": "#4A0100"
    },
    {
      "n": "forrest green",
      "c": "#154406"
    },
    {
      "n": "hunter green",
      "c": "#0B4008"
    },
    {
      "n": "bottle green",
      "c": "#044A05"
    },
    {
      "n": "darkgreen",
      "c": "#054907"
    },
    {
      "n": "british racing green",
      "c": "#05480D"
    },
    {
      "n": "evergreen",
      "c": "#05472A"
    },
    {
      "n": "dark teal",
      "c": "#014D4E"
    },
    {
      "n": "dark",
      "c": "#1B2431"
    },
    {
      "n": "night blue",
      "c": "#040348"
    },
    {
      "n": "deep violet",
      "c": "#490648"
    },
    {
      "n": "eggplant purple",
      "c": "#430541"
    },
    {
      "n": "deep brown",
      "c": "#410200"
    },
    {
      "n": "chocolate brown",
      "c": "#411900"
    },
    {
      "n": "dark olive",
      "c": "#373E02"
    },
    {
      "n": "racing green",
      "c": "#014600"
    },
    {
      "n": "navy blue",
      "c": "#001146"
    },
    {
      "n": "dark purple",
      "c": "#35063E"
    },
    {
      "n": "deep purple",
      "c": "#36013F"
    },
    {
      "n": "aubergine",
      "c": "#3D0734"
    },
    {
      "n": "dark plum",
      "c": "#3F012C"
    },
    {
      "n": "dark brown",
      "c": "#341C02"
    },
    {
      "n": "dark navy",
      "c": "#000435"
    },
    {
      "n": "very dark blue",
      "c": "#000133"
    },
    {
      "n": "midnight purple",
      "c": "#280137"
    },
    {
      "n": "very dark purple",
      "c": "#2A0134"
    },
    {
      "n": "dark maroon",
      "c": "#3C0008"
    },
    {
      "n": "very dark green",
      "c": "#062E03"
    },
    {
      "n": "dark forest green",
      "c": "#002D04"
    },
    {
      "n": "dark navy blue",
      "c": "#00022E"
    },
    {
      "n": "midnight",
      "c": "#03012D"
    },
    {
      "n": "very dark brown",
      "c": "#1D0200"
    },
    {
      "n": "almost black",
      "c": "#070D0D"
    },
    {
      "n": "black",
      "c": "#000000"
    }
]
  export default ColorList;
  
  
  