
import React from 'react'


import { observer } from 'mobx-react-lite'
import { useContext } from "react"

import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'

import RootContext from '../../data/RootStore'

import { ReactNode } from 'react'

import { mdiArrowUpBox } from '@mdi/js'
import { mdiArrowDownBox } from '@mdi/js'
import { mdiHeart } from '@mdi/js'
import { mdiCarKey } from '@mdi/js'
import { mdiCamera } from '@mdi/js'
import { mdiChatProcessing } from '@mdi/js'
import { mdiRoomServiceOutline } from '@mdi/js'
import { mdiPhoneClassic } from '@mdi/js'
import { mdiFlower } from '@mdi/js'
import { mdiHandHeartOutline } from '@mdi/js'

import { mdiLeadPencil } from '@mdi/js'
import { mdiBookOpenVariant } from '@mdi/js'
import { mdiMenu } from '@mdi/js'

import Icon from "@mdi/react"
import { TextField, Slider } from '@mui/material'
import { makeHomeData } from '../../data/MFStore'
import ColorItem from './ColorItem'
import { Title } from '@mui/icons-material'
import { fontSize } from '@mui/system'



type HomeEditProps = {
  idx: number,
}

const HomeEdit = observer((props: HomeEditProps) => {
  const rc = useContext(RootContext)
  const idx: number = props.idx
  let editSpace = 0
  if (rc.ui.editMode) {
    editSpace = rc.ui.screenWide / 3
  }

  const fontSize = '16pt'

  return (
    <div style={{
      display: 'flex', fontSize: fontSize, padding: 10, userSelect: 'none',
      flexDirection: 'column',
      alignItems: 'start',
      justifyContent: 'left'
    }}>

      Title
      <div>
        <input type='text' value={rc.mf.config.homeData[idx].main} style={{ maxWidth: 600, fontSize: fontSize }}
          onChange={(evt) => { rc.mf.config.homeData[idx].main = evt.target.value }}
        />
      </div>

      <div>
        <input type='text' value={rc.mf.config.homeData[idx].mainPos} style={{ maxWidth: 60, fontSize: fontSize }}
          onChange={(evt) => { rc.mf.config.homeData[idx].mainPos = (Number(evt.target.value) || 0) }}
        />
        <span onClick={() => { rc.mf.config.homeData[idx].mainPos++ }}><Icon path={mdiArrowUpBox} size={1} color='white' style={{ verticalAlign: 'middle' }} /></span>
        <span onClick={() => { rc.mf.config.homeData[idx].mainPos-- }}><Icon path={mdiArrowDownBox} size={1} color='white' style={{ verticalAlign: 'middle' }} /></span>
        Position: ( 0 - 100 )

      </div>

      <div>
        <input type='text' value={rc.mf.config.homeData[idx].mainSize} style={{ maxWidth: 60, fontSize: fontSize }}
          onChange={(evt) => { rc.mf.config.homeData[idx].mainSize = Number(evt.target.value) }}
        />
        <span onClick={() => { rc.mf.config.homeData[idx].mainSize++ }}><Icon path={mdiArrowUpBox} size={1} color='white' style={{ verticalAlign: 'middle' }} /></span>
        <span onClick={() => { rc.mf.config.homeData[idx].mainSize-- }}><Icon path={mdiArrowDownBox} size={1} color='white' style={{ verticalAlign: 'middle' }} /></span>
        Size: ( 0 - 30 )
      </div>
      <br />

      Sub-Title
      <div>
        <input type='text' value={rc.mf.config.homeData[idx].title} style={{ maxWidth: 600, fontSize: fontSize }}
          onChange={(evt) => { rc.mf.config.homeData[idx].title = evt.target.value }}
        />
      </div>

      <div>
        <input type='text' value={rc.mf.config.homeData[idx].titlePos} style={{ maxWidth: 60, fontSize: fontSize }}
          onChange={(evt) => { rc.mf.config.homeData[idx].titlePos = (Number(evt.target.value) || 0) }}
        />
        <span onClick={() => { rc.mf.config.homeData[idx].titlePos++ }}><Icon path={mdiArrowUpBox} size={1} color='white' style={{ verticalAlign: 'middle' }} /></span>
        <span onClick={() => { rc.mf.config.homeData[idx].titlePos-- }}><Icon path={mdiArrowDownBox} size={1} color='white' style={{ verticalAlign: 'middle' }} /></span>
        Position: ( 0 - 100 )
      </div>

      <div>
        <input type='text' value={rc.mf.config.homeData[idx].titleSize} style={{ maxWidth: 60, fontSize: fontSize }}
          onChange={(evt) => { rc.mf.config.homeData[idx].titleSize = Number(evt.target.value) }}
        />
        <span onClick={() => { rc.mf.config.homeData[idx].titleSize++ }}><Icon path={mdiArrowUpBox} size={1} color='white' style={{ verticalAlign: 'middle' }} /></span>
        <span onClick={() => { rc.mf.config.homeData[idx].titleSize-- }}><Icon path={mdiArrowDownBox} size={1} color='white' style={{ verticalAlign: 'middle' }} /></span>
        Size: ( 0 - 30 )
      </div>
      <br />

      Text
      <div>
        <textarea value={rc.mf.config.homeData[idx].text} style={{ width: 400, fontSize: fontSize }} rows={5}
          onChange={(evt) => { rc.mf.config.homeData[idx].text = evt.target.value }}
        />
      </div>

      <div>
        <input type='text' value={rc.mf.config.homeData[idx].textPos} style={{ maxWidth: 60, fontSize: fontSize }}
          onChange={(evt) => { rc.mf.config.homeData[idx].textPos = (Number(evt.target.value) || 0) }}
        />
        <span onClick={() => { rc.mf.config.homeData[idx].textPos++ }}><Icon path={mdiArrowUpBox} size={1} color='white' style={{ verticalAlign: 'middle' }} /></span>
        <span onClick={() => { rc.mf.config.homeData[idx].textPos-- }}><Icon path={mdiArrowDownBox} size={1} color='white' style={{ verticalAlign: 'middle' }} /></span>
        Position: ( 0 - 100 )
      </div>

      <div>
        <input type='text' value={rc.mf.config.homeData[idx].textSize} style={{ maxWidth: 60, fontSize: fontSize }}
          onChange={(evt) => { rc.mf.config.homeData[idx].textSize = Number(evt.target.value) }}
        />
        <span onClick={() => { rc.mf.config.homeData[idx].textSize += 0.25 }}><Icon path={mdiArrowUpBox} size={1} color='white' style={{ verticalAlign: 'middle' }} /></span>
        <span onClick={() => { rc.mf.config.homeData[idx].textSize -= 0.25 }}><Icon path={mdiArrowDownBox} size={1} color='white' style={{ verticalAlign: 'middle' }} /></span>
        Size: ( 0 - 30 )
      </div>
      <br />

      <br />
      Image URL ( blank for a text-only section)
      <input type='text' value={rc.mf.config.homeData[idx].url} style={{ maxWidth: 600, fontSize: fontSize }}
        onChange={(evt) => { rc.mf.config.homeData[idx].url = evt.target.value }}
      />

      <br />

      <div>
        <ColorItem title='Text' onChange={(val: any) => { rc.mf.config.homeData[idx].textColor = val }} color={rc.mf.config.homeData[idx].textColor} />
        <ColorItem title='Background' onChange={(val: any) => { rc.mf.config.homeData[idx].bgColor = val }} color={rc.mf.config.homeData[idx].bgColor} />
      </div>
      <br />
      <div>
        <Button variant="contained" onClick={() => { rc.mf.config.homeData.splice(idx, 0, makeHomeData()) }}>Add Above</Button>
        <Button variant="contained" onClick={() => { rc.mf.config.homeData.splice(idx + 1, 0, makeHomeData()) }}>Add Below</Button>
        <Button variant="contained" onClick={() => { rc.mf.config.homeData.splice(idx, 1) }}>Delete</Button>
      </div>

    </div >
  )
})

export default HomeEdit