
import { useEffect } from 'react';
import './App.css';

import { observer } from 'mobx-react-lite'

import { ThemeProvider } from '@mui/material/styles';
import { themeOptions } from './ui/ThemeOptions'
import Footer from './ui/Footer'
import Header from './ui/Header'

import RootContext from './data/RootStore'
import PageBlog from './ui/pages/Blog'
import PageContact from './ui/pages/Contact'
import PageGallery from './ui/pages/Gallery'
import PageGalleryView from './ui/pages/GalleryView'
import PageHome from './ui/pages/Home'
import PagePraise from './ui/pages/Praise'
import PageRentals from './ui/pages/Rentals'
import PageServices from './ui/pages/Services'

import { useContext } from "react"

export const globalPageMap = new Map<string, any>()



const App = observer(() => {

  document.title='Moda Floral'

  const rc = useContext(RootContext)

  const setSize = (event: any) =>{
    rc.ui.screenWide = window.innerWidth
    rc.ui.screenHigh = window.innerHeight
  }

  useEffect(()=>{
    rc.ui.screenWide = window.innerWidth
    rc.ui.screenHigh = window.innerHeight
    window.addEventListener('resize', setSize, true)

    window.onpopstate = function(event) {
      const pathgoal = window.location.pathname.replace('/','')
      console.log("location: " + pathgoal);
      if(pathgoal){
        rc.ui.curPage = pathgoal
      }
    }
  }, [])
    
  const headerOffset = rc.ui.isNarrow() ? '0px' : '40px'
  const footerOffset = rc.ui.isNarrow() ? '40px' : '80px'

  const pathgoal =  window.location.pathname.replace('/','')
  if(pathgoal){
    console.log(pathgoal)
    rc.ui.curPage = pathgoal
  }


  return (
    <div className="App">
        <ThemeProvider theme={themeOptions}>
        <Footer/>
          <div style={{ position: 'fixed', top: footerOffset, left:'0px',right:'0px',bottom:'0px',overflow:'auto'}}>
            {rc.ui.curPage === 'Blog' && <PageBlog/>}
            {rc.ui.curPage === 'Contact' && <PageContact />}
            {rc.ui.curPage === 'Gallery' && <PageGallery />}
            {rc.ui.curPage === 'GalleryView' && <PageGalleryView />}
            {rc.ui.curPage === 'Home' && <PageHome />}
            {rc.ui.curPage === 'Praise' && <PagePraise />}
            {rc.ui.curPage === 'Rentals' && <PageRentals />}
            {rc.ui.curPage === 'Services' && <PageServices />}
          </div>
        </ThemeProvider>
    </div>
  )
})


export default App;
