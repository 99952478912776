


import { observer } from 'mobx-react-lite'
import { useContext } from "react"


import RootContext from '../../data/RootStore'




import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import ColorItem from './ColorItem';



const GlobalEdit = observer(() => {
  const rc = useContext(RootContext)
  let editSpace = 0
  if (rc.ui.editMode) {
    editSpace = rc.ui.screenWide/3
  }

  let fontList:any = []
  if(rc.mf.config.font && rc.mf.config.font.list){
    fontList = rc.mf.config.font.list.map((i:string)=>{
      return(<MenuItem value={i}>{i}</MenuItem>)
    })
  }

  return (
<div>
                Global Colors:<br/>
            <ColorItem title='Frame' onChange={(val:any)=>{rc.mf.config.color.frame=val}} color={rc.mf.config.color.frame}/><br/>
            <ColorItem title='Icon' onChange={(val:any)=>{rc.mf.config.color.icon=val}} color={rc.mf.config.color.icon}/><br/>
            <ColorItem title='Face' onChange={(val:any)=>{rc.mf.config.color.face=val}} color={rc.mf.config.color.face}/><br/>
            <br/><br/>
            Global Fonts:<br/>
            <FormControl  size="small" style={{margin:5}}>
              <InputLabel id="demo-simple-select-label">Super</InputLabel>
              <Select
                value={rc.mf.config.font.super||'Tangerine'}
                style={{backgroundColor:'#FFFFFF44'}}
                onChange={(evt)=>{rc.mf.config.font.super=evt.target.value}}
              >
                {fontList}
              </Select>
            </FormControl><br/>
            <FormControl  size="small" style={{margin:5}}>
              <InputLabel id="demo-simple-select-label">Header</InputLabel>
              <Select
                value={rc.mf.config.font.header||'Tangerine'}
                style={{backgroundColor:'#FFFFFF44'}}
                onChange={(evt)=>{rc.mf.config.font.header=evt.target.value}}
              >
                {fontList}
              </Select>
            </FormControl><br/>
            <FormControl  size="small" style={{margin:5}}>
              <InputLabel id="demo-simple-select-label">Text</InputLabel>
              <Select
                value={rc.mf.config.font.text||'Tangerine'}
                style={{backgroundColor:'#FFFFFF44'}}
                onChange={(evt)=>{rc.mf.config.font.text=evt.target.value}}
              >
                {fontList}
              </Select>
            </FormControl>            


                </div>      
)
})

export default GlobalEdit