
import { useEffect } from 'react';

import { observer } from 'mobx-react-lite'
import { useContext } from "react"
import RootContext from '../../data/RootStore'
import { Box } from '@mui/material';

const showGallery= (photoSet:string, idx:number)=>{
  // @ts-expect-error
  window.$("#nanoGallery"+idx).nanoGallery({
    kind: 'flickr',
    photoset: photoSet,
    thumbnailWidth: 250,
    thumbnailHeight: 250,
    thumbnailLabel: {
      displayDescription: true,
      hideIcons: true,
      display: false,
      position: 'overImageOnBottom'

    },
    thumbnailHoverEffect: 'borderLighter',
    viewerToolbar: {
      standard: 'minimizeButton, previousButton, pageCounter, nextButton, playPauseButton, fullscreenButton, closeButton',
      minimized: 'minimizeButton'
    }
  });
  
  
}

const PageGalleryView = observer(() => {
  const rc = useContext(RootContext)

  let photoSet = ''
  if (rc.ui.showGallery === 0) {      photoSet = '72157661790745790' }
  else if (rc.ui.showGallery === 1) { photoSet = '72157662311032416' }
  else if (rc.ui.showGallery === 2) { photoSet = '72157660041695723' }

  useEffect(() => {
    showGallery(photoSet,rc.ui.showGallery)
  },[])

  let title=''
  if(rc.ui.showGallery===0){          title = 'Personals Gallery' }
  else if (rc.ui.showGallery === 1) { title = 'Ceremony Gallery' }
  else if (rc.ui.showGallery === 2) { title = 'Reception Gallery' }

  return (

<div style={{width:'90%'}} >
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }} onClick={()=>{rc.ui.curPage='Gallery'}}>
      <div style={{ fontFamily: rc.mf.config.font.super, fontSize: '4em', margin: 15 }}>
        {title}
      </div>
      <div style={{margin:-20}} onClick={(evt)=>{
        window.history.pushState({urlPath:'/Gallery'},'','/Gallery')
      }}>
        Click here to go back
      </div>
    </Box>
    <div style={{position:'absolute', padding:0, margin:0, top:100,bottom:0,left:0,right:0, overflow:'auto', overflowX:'hidden'}} className="scrollbar" id="scrollbar2">
      <div id={"nanoGallery"+rc.ui.showGallery} />
    </div>
</div>

  )
})



export default PageGalleryView