
import { observer } from 'mobx-react-lite'
import { useContext } from "react"
import RootContext from '../../data/RootStore'


const LinkIcons = observer(() => {
  const rc = useContext(RootContext)
  const size = 30
  let topMargin=5
  if(rc.ui.isNarrow()){
    topMargin = 10
  }
  return (
    <div style={{ marginTop: topMargin}}>
      <a target="_blank" rel="noreferrer" href="https://www.facebook.com/modafloral">
        <img src='https://www.freeiconspng.com/thumbs/logo-facebook-png/logo-facebook-png-transparent-image-28.png' width={size} height={size} alt="Facebook" />
      </a>
      &nbsp;<a target="_blank" rel="noreferrer" href="http://www.theknot.com/marketplace/moda-floral-and-event-design-greater-sacramento-area-ca-512321?utm_source=vendor_website&utm_medium=banner&utm_term=b1a48fe1-85e4-4f3d-bbe6-7f381ca2cbb5&utm_campaign=vendor_badge_assets">
        <img src="//www.xoedge.com/myaccount/2012/grab-a-badge/social/gloss/large/rounded.png" width={size} height={size} alt="TheKnot.com" />
      </a>
      { !rc.ui.isNarrow() && <br /> }

      &nbsp;<a target="_blank" rel="noreferrer" href="http://www.weddingwire.com/biz/moda-floral-event-design-sacramento/9671bf23d89b72c7.html">
        <img src="https://www.weddingwire.com/assets/img/dropdown/app.png" width={size} height={size} alt="Wedding Wire" />
      </a>
      &nbsp;<a target="_blank" rel="noreferrer" href="https://www.instagram.com/modafloralevents/">
        <img title="Instagram" src='https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/800px-Instagram_logo_2016.svg.png' alt="Instagram" width={size} height={size} />
      </a>

    </div>
  )
})

export default LinkIcons