import { HMobiledata } from "@mui/icons-material"
import { makeAutoObservable } from "mobx"
import { mainModule } from "process"
import {RootStore} from './RootStore'

import {ColorEntry} from './types/ColorEntry'

type HomeData={
  main?: string
  title?: string
  text?: string

  mainPos: number
  titlePos: number
  textPos: number

  mainSize: number
  titleSize: number
  textSize: number

  url?: string

  bgColor:  ColorEntry
  textColor: ColorEntry
  plateColor:  ColorEntry
}

export function makeHomeData():HomeData{
  return {
    main:'main',
    title:'title',
    text:'text',
    url:'',
    mainPos:90,
    titlePos:70,
    textPos:30,
    mainSize:1,
    titleSize:1,
    textSize:1,
    bgColor:{c:'#F0F0F0',n:'white'},
    textColor:{c:'#0F0F0F',n:'white'},
    plateColor:{c:'#FFFFFF',n:'white'},
  }
}

type GalleryData={
  text?: string
  url?: string
}

type ServiceData={
  intro: string
  outro: string
  serviceList: string
}

type RentalData={
  normalList: string
  textOnlyList: string
}

type PraiseData={
  name?: string
  text?: string
  url?: string
}

type ConfigData={
  homeData:Array<HomeData>
  galleryData:Array<GalleryData>
  praiseData:Array<PraiseData>
  serviceData:ServiceData
  rentalData:RentalData
  mqPhone:string
  mqEMail:string
  color:{
    frame:ColorEntry
    icon:ColorEntry
    face:ColorEntry
  }
  font:{
    super?:string
    header?:string
    copy?:string
    text?:string
    list?:string[]
  }

}

type QuoteData={
  names?: string
  phone?: string
  email?: string
  pinBoard?: string
  color1?: ColorEntry
  color2?: ColorEntry
  color3?: ColorEntry    
  notes?: string
}


export class MFStore{

  root:RootStore

  blogList:any
  blogPost:any
  curPost:number=0
  colorFilter:string=''

  savePassword: string = ''


  constructor(rootStore: RootStore){
    this.root = rootStore
    makeAutoObservable(this)
    this.loadConfig()
    this.loadPost()
  }

  counter:number = 0

  server = 'https://zi1xyc1ycg.execute-api.us-west-2.amazonaws.com/prod'

  quote:QuoteData={
  }
  quoteSent=false

  config:ConfigData = {
    homeData:[],
    galleryData:[],
    serviceData:{
      intro: '',
      outro: '',
      serviceList: ''    
    },
    rentalData:{
      normalList: '',
      textOnlyList: ''
    },
    praiseData:[],
    mqPhone:'',
    mqEMail:'',
    color:{
      frame:{c:'',n:''},
      icon:{c:'',n:''},
      face:{c:'',n:''},
    },
    font:{
      super:'',
      header:'',
      text:'',
      list:['Domine','Vollkorn','Satisfy','Crete Round',
      'Courgette','Itim','Sacramento','Sriracha',
    'Italianno','Charm','Quando']
    }
  
  }

  deletePraiseItem(idx:number){
    if(this.config.praiseData[idx].name==='delete'){
      this.config.praiseData.splice(idx,1)
    }
  }

  addPraiseItem(){
    this.config.praiseData.unshift({url:'url',name:'name',text:'text'})
  }

  reset(){
    this.counter = 0
  }

  get timesFive(){
    return this.counter*5
  }


  loadConfig(){
    fetch(`${this.server}/config`, {
      cache:'no-cache',
      headers:{
        'Content-Type':'application/json'
      },
      method:'GET'
    }).then((res)=>{
      if(res.ok){  return res.json()      }
      else{        return res.text().then( (errBody)=>{throw new Error(errBody)}) }
    })
    .then((res)=>{  
      this.config=res 
    })
    .catch((err)=>{ console.log(err.Message) })
  }

  saveConfig(){
    fetch(`${this.server}/config`, {
      cache:'no-cache',
      headers:{
        'Content-Type':'application/json',
        'x-api-key':this.savePassword
      },
      body:JSON.stringify(this.config),
      method:'POST'
    }).then((res)=>{
      if(res.ok){  return res.json()      }
      else{        return res.text().then( (errBody)=>{throw new Error(errBody)}) }
    })
    .then((res)=>{
      // console.log(res) // don't really care unless something goes wrong.
    })
    .catch((err)=>{ 
      window.alert('ERROR!  Changes were NOT saved!')
      console.log(err.Message) 
    })
  }

  // ?pid=676603789598363684
  loadPost(pid?:string){
    let pidVal =''
    if(pid){
      pidVal='?pid='+pid
    }
    fetch(`${this.server}/blog${pidVal}`, {
      cache:'no-cache',
      headers:{
        'Content-Type':'application/json'
      },
      method:'GET'
    }).then((res)=>{
      if(res.ok){  return res.json()      }
      else{        return res.text().then( (errBody)=>{throw new Error(errBody)}) }
    })
    .then((res)=>{
      if(pid){
        this.blogPost=res
        console.log(res)
      }
      else{
        this.blogList=res
      }
      console.log(res) // don't really care unless something goes wrong.
    })
    .catch((err)=>{ 
      console.log(err) 
    })
  }

  sendQuote(){
    const saneThis = this
    fetch(`${this.server}/quote`, {
      cache:'no-cache',
      headers:{
        'Content-Type':'application/json'
      },
      body:JSON.stringify(this.quote),
      method:'POST'
    }).then((res)=>{
      if(res.ok){  return res.json()      }
      else{        return res.text().then( (errBody)=>{throw new Error(errBody)}) }
    })
    .then((res)=>{
      // console.log(res) // don't really care unless something goes wrong.
      saneThis.quoteSent = true
    })
    .catch((err)=>{ 
      window.alert('WARNING!  It looks like something technical went wrong.  Please send your quote again later.  Very sorry about this!')
      console.log(err.Message) 
    })
  }



}