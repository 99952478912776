import { makeAutoObservable } from "mobx"
import { RootStore } from './RootStore'

import  ColorListAll from '../ui/parts/ColorListAll'
import  ColorListPantone from '../ui/parts/ColorListPantone'

import {ColorEntry} from './types/ColorEntry'

export class UIStore {

  root: RootStore

  constructor(rootStore: RootStore) {
    this.root = rootStore
    makeAutoObservable(this)
  }

  workCounter: number = 0
  screenWide: number = 0
  screenHigh: number = 0

  navDrawerOpen: boolean = false

  curPage: string = 'Home'

  editMode: boolean = false
  editCount: number = 0
  showGallery: number = -1

  isNarrow(){
    return this.screenWide<950
  }

  reset() {
  }

  bumpEdit(){
    this.editCount++
    if (this.editCount==2){
      this.editMode=true
    }
    if (this.editCount == 3) {
      this.editCount=0
      this.editMode = false
    }
  }

  get pantoneListFormatted():Array<Array<ColorEntry>>
  {
    const res = ColorListPantone.map( (clrList:Array<ColorEntry>) =>{
      return clrList.map( (clr:ColorEntry)=>{
        return {
          n:clr.n,
          c:clr.c,
          text:clr.n.trim().toLowerCase().split(' ').join('\n')
        }
      })
    })
    return res
  }

  get allColorListFormatted():Array<Array<ColorEntry>>
  {
    const temp = ColorListAll.map( (clr:ColorEntry)=>{
        return {
          n:clr.n,
          c:clr.c,
          text:clr.n.trim().toLowerCase().split(' ').join('\n'),
          sort:clr.n.trim().toLowerCase().split(' ').reverse().join(' '),
          key:clr.n.trim().toLowerCase().split(' ').reverse()[0],
        }
    })

    const res:Array<Array<ColorEntry>> = []
    let cur:Array<ColorEntry> = []
    let curKey:string|undefined = ''

    temp.sort((a,b)=>{return (a.sort > b.sort)?1:-1})

    temp.forEach((clr:ColorEntry)=>{
      /*
      if(clr.key!==curKey){
        if(cur){
          res.push(cur)
        }
        cur = []
        curKey = clr.key
      }
      */
      if(cur.length===7){
        res.push(cur)
        cur = []
      }
      cur.push(clr)
    })
    return res
  }



}