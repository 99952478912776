
import { Button, FormControl, InputLabel, MenuItem, Select, Slider, TextField } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useContext } from "react"
import RootContext from '../../data/RootStore'
import ColorItem from '../parts/ColorItem'
import {makeHomeData} from '../../data/MFStore'
import HomeEdit from '../parts/HomeEdit'
import GlobalEdit from '../parts/GlobalEdit'


const PageHome = observer(() => {
  const rc = useContext(RootContext)
  const size = 30
  let topMargin = 5

  if (rc.ui.isNarrow()) {
    topMargin = 10
  }
  let editSpace = rc.ui.screenWide/3
  
  let items:Array<any>=[]
    items = rc.mf.config.homeData.map((i, idx) => {

      const layoutParts=[<div/>,<div/>,<div/>]

      // the 2- reverses the order.  Mui Slider is dumb.
      if(i.text){
        layoutParts[2] = 
        <div style={{ fontSize: `calc(14px + ${rc.mf.config.homeData[idx].textSize||1}vw)`, fontFamily: rc.mf.config.font.text, 
                      width: '95%',marginLeft:'auto',marginRight:'auto', textAlign:'center', 
                      padding:10, 
                      textShadow:'0px 0px 15px rgba(0,0,0,1)',
                      position:'absolute',
                      left: '50%',
                      whiteSpace:'pre-wrap',
                      transform: 'translate(-50%, -50%)',                      
                      top: (rc.ui.screenHigh-120)*((100-rc.mf.config.homeData[idx].textPos)/100) }}>
          {i.text}</div>
      }
      if(i.title){
        layoutParts[1] = 
        <div style={{ fontSize: `calc(14px + ${rc.mf.config.homeData[idx].titleSize||2}vw)`, fontFamily: rc.mf.config.font.header, 
                      width: '95%',marginLeft:'auto',marginRight:'auto', textAlign:'center', 
                      padding:10, 
                      textShadow:'0px 0px 5px rgba(0,0,0,1)',
                      position:'absolute',
                      whiteSpace:'pre-wrap',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',                      
                      top: (rc.ui.screenHigh-120)*((100-rc.mf.config.homeData[idx].titlePos)/100) }}>
          {i.title}</div>
      }
      if(i.main){
        layoutParts[0] = 
        <div style={{ fontSize: `calc(14px + ${rc.mf.config.homeData[idx].mainSize||10}vw)`, fontFamily: rc.mf.config.font.super, 
                      width: '95%',marginLeft:'auto',marginRight:'auto', textAlign:'center', 
                      padding:10, 
                      textShadow:'0px 0px 5px rgba(0,0,0,1)',
                      position:'absolute',
                      whiteSpace:'pre-wrap',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',                      
                      top: (rc.ui.screenHigh-120)*((100-rc.mf.config.homeData[idx].mainPos)/100) }}>                        
          {i.main}</div>
      }

      if(i.text && !i.url){
        layoutParts[2] = 
        <div style={{ fontSize: `calc(14px + ${rc.mf.config.homeData[idx].textSize||1}vw)`, 
                      fontFamily: rc.mf.config.font.text, 
                      width: (rc.ui.screenWide),
                      textShadow:'0px 0px 35px rgba(0,0,0,1)',
                      backgroundColor:i.bgColor.c,
                      whiteSpace:'pre-wrap', minHeight:80
                       }}>
                        <div style={{padding:30,width: (rc.ui.screenWide - 100),marginLeft:'auto',marginRight:'auto', textAlign:'center', }}>
                        {i.text}
                        </div>
        </div>

      }

      let imgurl = i.url
      if(rc.ui.isNarrow() && imgurl){
        imgurl = imgurl.replace('s3000','s500')
        imgurl = imgurl.replace('s2400','s500')
      }

      // this returns ONE SECTION of the whole page.
      return (
        <div style={{ width: rc.ui.screenWide - 5, display:'flex', flexDirection:'row' }}>
          <div style={{ width: rc.ui.screenWide , backgroundColor: i.bgColor.c, height: i.url?(rc.ui.screenHigh-120):'auto', 
                        background: 'url('+imgurl+') no-repeat center center',
                        backgroundSize: 'cover', color: i.textColor.c,
                        minHeight: rc.ui.editMode?rc.ui.screenHigh-120:'auto', display:'flex', flexDirection:'column', justifyContent:'space-around', position:'relative' }}
                        >
            {layoutParts}            

            { rc.ui.editMode &&
            <>
              <div style={{  minWidth: 450, 
                  position:'absolute', right:30, top:30, backdropFilter: 'blur(3px)', 
                  border:'5px solid lightblue',borderRadius:10 }}><HomeEdit idx={idx}/></div>
                  { idx===0 &&
                  <div style={{ height: 500,
                    position:'absolute', left:30, top:30, backgroundColor:'grey',
                    border:'5px solid lightblue',borderRadius:10 }}><GlobalEdit/></div>
                  }
              </>                
            }
          </div>
        </div>


      )
    })

  return (
    <>
    <div style={{ position:'absolute', top:0, bottom:0, left:0, right:0, minHeight: rc.ui.screenHigh - 125, overflowX: 'hidden'}}  className="scrollbar" id="scrollbar2" >
      {items}
    </div>
    </>
    
  )
})


export default PageHome