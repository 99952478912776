import { createTheme } from '@mui/material/styles';

export const themeOptions= createTheme({
  palette: {
    primary: {
      main: '#BFD25B',
    },
    secondary: {
      main: '#FFF59D',
    },
  },
})