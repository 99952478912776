import React from "react";

import { makeAutoObservable } from "mobx"

// purely for sepearation of concerns
import {MFStore} from './MFStore'
import {UIStore} from './UIStore'

import { configure } from "mobx"

configure({
  enforceActions: "never"  // I don't want to define a setter just to change a field in a click handler.
})


export class RootStore {
  mf: MFStore
  ui:UIStore

  constructor() {
    this.mf = new MFStore(this)
    this.ui = new UIStore(this)
    makeAutoObservable(this)
  }
}

// make this available to anyone who wants it.  
// Global Singlton, but in a good way.
const RootContext = React.createContext<RootStore>(new RootStore())
RootContext.displayName = 'RootContext'

export default RootContext
